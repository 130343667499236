import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
// Vue.use(Vuetify);
// export default new Vuetify({
// });



import Vuetify, {VBtn, VApp, VMain, VSelect} from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        VBtn, VApp, VMain, VSelect
    }
})

export default new Vuetify();
    