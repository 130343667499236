<template>
    <div class="login-content">
        <div class="total-header-login">
          <div class="close-login" @click="closeLogin">
            <i class="iconfont icon-guan"></i>
          </div>
          <div class="login-title">
            <img src="../assets/logo.png"/>
            <span>BGM猫</span>
          </div>
        </div>
        <div class="loginTitle">
            {{loginTitle}}
        </div>
        <!-- 验证码登录 -->
        <div class="login-register loginByErcode-register funcContainer" v-if="router == 0">
            <div class="telPhNum">
                <span>+86</span>
                <span>|</span>
                <input v-model="user.telPhNum"
                    max-length="11"
                    placeholder="请输入手机号"
                    pattern="[0-9]*"  
                    v-number-only 
                    type="telephone" 
                    maxlength="11" 
                    />
                <i v-show="user.telPhNum.length > 0" 
                    style="font-size: 14px;" 
                    class="iconfont icon-icon_lable_close_12"
                    @click="clearTelPhNum">
                </i>
            </div>
            <div class="verCode">
                <input v-model="user.verCode" v-number-only  placeholder="请输入验证码"/>
                <span @click="getVerCode" 
                    :style="user.telPhNum.length == 11 && (verCodeTip == '获取验证码' || verCodeTip == '重新获取') 
                    ? 'color: #5490F4;' : 'color: rgba(0, 0, 0, 0.4);'">
                    {{verCodeTip}}
                </span>
            </div>
            <div class="returnErrReason" 
                :style="errReasonTip ? 'opacity: 1;' : 'opacity: 0;'">
                {{errReason}}
            </div>
            <div class="tips-prompt">
                <i @click="changeAgreeTop" v-if="!agreeTip" 
                    style="color: #000000;opacity: 0.4;" 
                    class="iconfont icon-xieyiweixuan">
                </i>
                <i @click="changeAgreeTop" v-if="agreeTip" 
                    class="iconfont icon-xieyixuanzhong">
                </i>
                <div>首次登录会自动创建新账号，且代表同意<span>《隐私政策》</span>和<span>《用户政策》</span></div>
            </div>
            <div class="loginReg-container">
                <v-btn class="ma-2 loginRegButton" 
                    :class="submitState ? '' : 'cantsubmit'" 
                    :loading="loading" 
                    :disabled="loading" 
                    color="secondary"
                    @click="loginReg('vercode')">
                    一键登录/注册
                </v-btn>
            </div>
            <div class="buttonBottom" @click="jumpLoginStep(1, null)">
                密码登录
            </div>
        </div>
        <!-- 密码登录 -->
        <div class="loginByPassWord funcContainer" v-if="router == 1">
            <div class="telPhNum">
                <span>+86</span>
                <span>|</span>
                <input v-model="user.telPhNum"
                    max-length="11"
                    placeholder="请输入手机号"
                    pattern="[0-9]*"  
                    v-number-only 
                    type="telephone" 
                    maxlength="11" 
                    />
                <i v-show="user.telPhNum.length > 0" 
                    style="font-size: 14px;" 
                    class="iconfont icon-icon_lable_close_12"
                    @click="clearTelPhNum">
                </i>
            </div>
            <div class="passWord">
              <input v-model="user.passWord" id="loginPassWord" type="password" placeholder="请输入密码"/>
              <span>
                <i v-if="!passwordTip" 
                    style="color:#D9D9D9;"
                    class="iconfont icon-biyan" 
                    @click="changePassWordType"></i>
                <i v-if="passwordTip" 
                    class="iconfont icon-yanjing" 
                    style="color:#D9D9D9;"
                    @click="changePassWordType">
                </i>
                <i :style="user.passWord.length > 0 ? 'opacity: 1;' : 'opacity: 0;'"
                    style="font-size: 14px;color:rgba(0,0,0,0.4);" 
                    class="iconfont icon-icon_lable_close_12"
                    @click="clearPassword">
                </i>
              </span>
            </div>
            <span class="returnErrReason" 
                :style="errReasonTip ? 'opacity: 1;' : 'opacity: 0;'">
                {{errReason}}
            </span>
            <div style="height: 20px;"></div>
            <div class="loginReg-container">
                <v-btn class="ma-2 loginRegButton" 
                    :class="submitState ? '' : 'cantsubmit'" 
                    :loading="loading" 
                    :disabled="loading" 
                    color="secondary"
                    @click="loginReg('password')">
                    一键登录
                </v-btn>
            </div>
            <div class="buttonBottom">
                <span @click="jumpLoginStep(0, null)">验证码登录</span>|<span @click="jumpLoginStep(2, 'loginForgetPwd')">忘记密码</span>|<span @click="jumpLoginStep(0, null)">立即注册</span>
            </div>
        </div>
        <!-- 忘记密码  身份验证-->
        <div class="forget-password funcContainer" v-if="router == 2">
            <div class="telPhNum">
                <span>+86</span>
                <span>|</span>
                <input v-model="user.telPhNum"
                    max-length="11"
                    placeholder="请输入手机号"
                    pattern="[0-9]*"  
                    v-number-only 
                    type="telephone" 
                    maxlength="11" 
                    />
                <i style="font-size: 14px;" 
                    v-show="user.telPhNum.length > 0"
                    class="iconfont icon-icon_lable_close_12"
                    @click="clearTelPhNum">
                </i>
            </div>
            <div class="verCode">
                <input v-model="user.verCode"  placeholder="请输入验证码"/>
                <span @click="ForgetPassWordgetVerCode" :style="user.telPhNum.length == 11 && (verCodeTip == '获取验证码' || verCodeTip == '重新获取')
                    ? 'color: #5490F4;' : 'color: rgba(0, 0, 0, 0.4);'">{{verCodeTip}}</span>
            </div>
            <div class="returnErrReason" :style="errReasonTip ? 'opacity: 1;' : 'opacity: 0;'">{{errReason}}</div>
            <div class="loginReg-container">
                <v-btn class="ma-2 loginRegButton" 
                    :class="submitState ? '' : 'cantsubmit'" 
                    :loading="loading" 
                    :disabled="loading" 
                    color="secondary"
                    @click="forgetPassword">
                    确定
                </v-btn>
            </div>
            <div class="buttonBottom">
                <span v-if="setPwdFrom == 'loginForgetPwd'" @click="jumpLoginStep(1, null)" style="margin-right: 0;">返回</span>
                <span v-if="setPwdFrom.indexOf('user') > -1 && loginTitle != '修改手机号'" @click="jumpLoginStep(5, null)" style="margin-right: 0;">无法接收验证码</span>
                <span v-if="loginTitle == '修改手机号' && setPwdFrom.indexOf('user') > -1" 
                  @click="closeLogin" style="margin-right: 0;">
                    取消
                </span>
            </div>
        </div>
        <!-- 忘记密码->修改密码 首次注册->设置密码 个人中心->设置密码 个人中心->修改密码 -->
        <div class="setPassword funcContainer" v-if="router == 3">
            <div class="setPassWord-passWord passWord">
              <input v-model="user.passWord" type="password" id="loginPassWord" placeholder="请设置新密码"/>
              <span>
                <i v-if="!passwordTip" 
                    style="color:#D9D9D9;"
                  class="iconfont icon-biyan" 
                  @click="changePassWordType">
                </i>
                <i v-if="passwordTip" 
                    style="color:#D9D9D9;"
                  class="iconfont icon-yanjing" 
                  @click="changePassWordType"></i>
                <i style="font-size: 14px;color:rgba(0,0,0,0.4);" 
                    :style="user.passWord.length > 0 ? 'opacity: 1;' : 'opacity: 0;'"
                    class="iconfont icon-icon_lable_close_12"
                     @click="clearPassword">
                </i>
              </span>
            </div>
            <div class="password-grade" :style="(strengthPwd || user.passWord.length == 0) ? ' color: #B8B8B8;' : ' color: red;'">
                6-16位数字、字母混合密码
            </div>
            <div class="loginReg-container" style="margin-top: 126px;">
              <v-btn class="ma-2 loginRegButton"
              :class="submitState ? '' : 'cantsubmit'"  
                :loading="loading" 
                :disabled="loading" 
                color="secondary"
                @click="submitPassWord">
                确定
              </v-btn>
            </div>
            <div class="buttonBottom">
                <span style="margin-right: 0;" v-if="setPwdFrom == 'loginForgetPwd'" @click="jumpLoginStep(2)">返回</span>
                <span style="margin-right: 0;" v-if="setPwdFrom == 'loginSetPwd'"  @click="closeLogin">跳过</span>
                <span style="margin-right: 0;" v-if="setPwdFrom.indexOf('user') > -1" @click="closeLogin">取消</span>
            </div>
        </div>
        <!-- 设置成功 -->
        <div class="setPassWord-success" v-if="router == 4">
            <div class="password-tip">
                恭喜你设置成功！
            </div>
            <div class="setPwdSuccess">
                <img src="../assets/setPwdSuccess.png"/>
            </div>
            <div v-if="setPwdFrom == 'loginForgetPwd'" 
                class="button" 
                @click="setSuccLoginSystem">
                完成并登录
            </div>
            <div v-if="setPwdFrom == 'userSetPwd'" 
                class="button" 
                @click="closeLogin">
                完成
            </div>
        </div>
        <!-- 联系客服 -->
        <div class="contactUS" v-if="router == 5">
            <div class="contactUS-title">
                联系客服
            </div>
            <div class="contactUS-img">
                <img src="../assets/contactUS.png"/>
            </div>
        </div>
        <!-- 退出登录 -->
        <div class="logOutSystem" v-if="router == 11">
            <div class="logOutSystem-title">是否退出登录？</div>
            <div class="logOutSystem-button">
                <span @click="determineLogOutSystem">确认</span>
                <span @click="cancelLoginOut">取消</span>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import {axiosGet, axiosPost} from "../../src/axios.js"
import {
    LOGINREG, GETVERCODE, 
    CHANGEINFORMATION, FORGETPWD, 
    CHANGTELGETVERCODE, CHANGEUSERPHONE, 
    FORGETPWDGETVERCODE, GETUSERINFORMATION} from "../../src/api.js"
import axios from 'axios';
import { send_record, set_uid, set_block, set_start } from "../utils/umeng_util";
export default {
    data: () => ({
        router: -1, // 验证码登录/密码登录/忘记密码/设置新密码/设置成功/身份验证/更换手机号/退出登录
        loginTitle: "登录",

        submitState: false, 
        agreeTip: false, // 登录是否同意协议
       
        setPwdFrom:'',// 用来判断忘记密码的来源
        strengthPwd: false, // 密码强度

        errReasonTip: false, // 错误原因是否显示
        errReason: "密码不正确", // 错误原因

        loading: false, // 注册按钮的加载状态

        passwordTip: false, // 密码登录是否显示密码 false不显示 true显示

        user: {
            telPhNum: '', // 手机号 
            verCode: '', // 验证码
            passWord: '', // 密码
            usedTelNum: '' // 要替换的手机号
        },


        timer: null, // 验证码倒计时计时器
        verCodeTips: true, // 是否可以获取验证码
        countDown: 60, // 获取验证码倒计时
        verCodeTip: "获取验证码",
    }), 
    directives: {
        numberOnly: {
            bind: function(e) {
                e.handler = function() {
                    e.value = e.value.replace(/\D+/, '')
                }
                e.addEventListener('input', e.handler)
            },
            unbind: function(e) {
                e.removeEventListener('input', e.handler)
            }
        }
    },
    watch: {
        user: {
            handler(val, oldVal) {
                if(val.telPhNum[0] != 1) {
                    this.user.telPhNum = ''
                }
                if(val.verCode.length > 6) {
                    this.user.verCode = val.verCode.substring(0,6);
                }
                if(val.passWord.length > 16) {
                    this.user.passWord = val.passWord.substring(0,16);
                }

                if(this.router == 0) {
                    if(val.telPhNum.length == 11  && val.verCode.length == 6 &&  this.agreeTip) {
                        this.submitState = true;
                    } else {
                        this.submitState = false;
                    }
                } else if(this.router == 1) {
                    if(val.telPhNum.length == 11 && (val.passWord.length >= 6 && val.passWord.length <=16)) {
                        this.submitState = true;
                    } else {
                        this.submitState = false;
                    }
                } else if(this.router == 2) {
                    if(val.telPhNum.length == 11  && val.verCode.length == 6) {
                        this.submitState = true;
                    } else {
                        this.submitState = false;
                    }
                } else if(this.router == 3) {
                    let reg = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,16}');
                    if(reg.test(val.passWord)) {
                        this.strengthPwd = true;
                        this.submitState  = true;
                    } else {
                        this.strengthPwd = false;
                        this.submitState = false;
                    }
                } else if(this.router == 5) {
                    if(val.verCode.length == 6) {
                        this.submitState = true;
                    }
                } else if(this.router == 6) {
                    if(val.telPhNum.length == 11  && val.verCode.length == 6) {
                        this.submitState = true;
                    } else {
                        this.submitState = false;
                    }
                }
            },
            deep: true
        },
        $route: {
            handler: function(val, oldVal){
                this.jumpLoginStep(val.query.index, this.setPwdFrom)
            },
            deep: true
        }
    },
    filters: {
        delayTel(index) {
            return index.substring(0,3) + "******" + index.substring(9,12)
        }
    },
    beforeMount() {
    },
    mounted() {
        if(this.$route.query.index == undefined){
            this.jumpLoginStep(0, null)
        }
        this.jumpLoginStep(this.$route.query.index, this.$route.query.setPwdFrom)
    },
    methods: {
        determineLogOutSystem() {
            // 退出登录
            send_record("user_logout");
            localStorage.removeItem("token");
            this.$router.push({path: '/'});
            this.user.telPhNum = "";
        },
        cancelLoginOut() {
            // 取消退出
            this.$router.back();
        },
        forgetPassword() {
            if(!this.submitState) {
                return
            }
            this.loading = true;
            this.submitState = false;
            let data ;
            if(this.setPwdFrom == "loginForgetPwd" || this.setPwdFrom == "userChangePwd" || this.setPwdFrom == "userSetPwd") {
                // 忘记密码->电话号 && 验证码 获取check
                data = {
                    phone: this.user.telPhNum,
                    verify: this.user.verCode
                }
                axiosPost(FORGETPWD,  qs.stringify(data))
                    .then((res) => {
                        this.loading = false;
                        this.submitState = true;
                        this.jumpLoginStep(3, this.setPwdFrom)
                        if(res.data.check != null) {
                            localStorage.setItem("check",  res.data.check);
                        }
                    }).catch( err => {
                        this.loading = false;
                        this.submitState = true;
                        this.$message.info(err.data.msg)
                    })
            } else if(this.setPwdFrom == "userChangeTel") {
                if(localStorage.getItem("x") == null) {
                    data = {
                        phone: this.user.telPhNum,
                        verify: this.user.verCode
                    }
                } else {
                    data = {
                        phone: this.user.telPhNum,
                        verify: this.user.verCode,
                        x: localStorage.getItem("x")
                    }
                }
                axiosPost(CHANGEUSERPHONE, qs.stringify(data))
                    .then(res => {
                        this.errReasonTip = false;
                        this.loading = false;
                        this.submitState = true;
                        if(res.data.x) {
                            localStorage.setItem("x", res.data.x);
                            this.loginTitle = "修改手机号";
                            clearInterval(this.timer);
                            this.verCodeTips = true; 
                            this.countDown = 60; 
                            this.verCodeTip = "获取验证码";
                        } else {
                            send_record("user_change_phone");
                            localStorage.removeItem("x");
                            this.closeLogin();
                        }
                        this.user.verCode = "";
                        this.user.telPhNum = "";
                    }).catch(err => {
                        this.loading = false;
                        this.submitState = true;
                        if(err.data.code == -1) {
                            this.errReasonTip = true;
                            this.errReason = err.data.msg;
                        } else {
                            this.errReasonTip = false;
                            this.$message.info(err.data.msg)
                        }
                    })
            }
        },
        submitPassWord() {
            // 提交密码
            if(!this.submitState) {
                return
            }
            this.loading = true;
            this.submitState = false;
            let data;
            // 提交密码
            if(this.setPwdFrom == "loginSetPwd") {
                // 注册新用户-》设置新密码
                data = {
                    phone: this.user.telPhNum,
                    new_pwd: this.user.passWord
                }
                axiosPost(CHANGEINFORMATION, qs.stringify(data))
                    .then((res) => {
                        send_record("user_set_password");
                        this.loading = false;
                        this.submitState = true;
                        // console.log("密码设置成功", res);
                        this.$router.push({path: '/login?index=4'});
                    }).catch( err => {
                        // console.log("密码设置失败", err)
                        this.loading = false;
                        this.submitState = true;
                        this.$message.info(err.data.msg)
                    })
            } else {
                // 忘记密码-》设置新密码
                // 忘记密码 修改密码
                data = {
                    phone: this.user.telPhNum,
                    pwd: this.user.passWord,
                    check: localStorage.getItem('check')
                }
                axiosPost(FORGETPWD, qs.stringify(data))
                    .then((res) => {
                        this.loading = false;
                        this.submitState = true;
                        if(this.setPwdFrom == "loginForgetPwd") {
                            send_record("user_change_password");
                        } else if(this.setPwdFrom == "userChangePwd") {
                            send_record("user_change_password");
                        } else if(this.setPwdFrom == "userSetPwd") {
                            send_record("user_set_password");
                        }
                        this.$router.push({path: '/login?index=4'});
                    }).catch(  err => {
                        this.loading = false;
                        this.submitState = true;
                        if(err.data.code == -1) {
                            this.$message.info(err.data.msg)
                        } else {
                            this.$message.error('未知错误,请重试!')
                        }
                    })
            }
        },
        setSuccLoginSystem() {
            // 完成并登录
            if(this.setPwdFrom == "loginForgetPwd") {
                this.loginSystem("passWord")
            }
        },
        loginReg(str) {
            // 一键登录/注册
            if(!this.submitState) {
                return
            }
            let data;
            if(str == "vercode") {
                data = {
                    phone: this.user.telPhNum,
                    verify: this.user.verCode
                }
            } else if(str == "password") {
                data = {
                    phone: this.user.telPhNum,
                    pwd: this.user.passWord
                }
            }
            this.submitState = false;
            this.loading = true;
            axiosPost(LOGINREG, qs.stringify(data)).then(res=> {
                this.errReasonTip = false;
                this.loading = false;
                this.submitState = true;
                this.user.verCode = '';
                set_block();
                this.getUserInformation();
                if(res.data.isReg) {
                    send_record("user_register");
                } else {
                    str == "verCode" ? send_record("user_login",{type:"手机号登录"}) : send_record("user_login",{type:"密码登录"});
                }
                if(res.data.needPassword) {
                    // 注册 跳转到设置密码界面
                    // this.$router.push({path: '/login?index=3&setPwdFrom=loginSetPwd'});
                    this.jumpLoginStep(3, "loginSetPwd")
                    localStorage.setItem("token", res.data.token)
                } else {
                    localStorage.setItem("token", res.data.token);
                    this.closeLogin()
                }
            }).catch(err  => {
                this.loading = false;
                if('data' in err && err.data.code == -1) {
                    this.errReasonTip = true;
                    this.errReason = err.data.msg;
                } else {
                    this.errReasonTip = false;
                    this.$message.info(err.data.msg)
                }
            })
        },
        getVerCode() {
            // 获取验证码
            if(this.user.telPhNum.length < 11) {
                return
            } 
            if(this.verCodeTips){
                this.verCodeTip  = this.countDown + "秒后重新获取"
                this.timer = setInterval(() => {
                this.countDown--;
                this.verCodeTip  = this.countDown + "秒后重新获取"
                if(this.countDown == -1) {
                    this.verCodeTips = true;
                    this.verCodeTip = "重新获取";
                    this.countDown = 60;
                    clearInterval(this.timer)
                }
                }, 1000)
            } else {
                return
            }
            if(this.verCodeTips) {
                this.verCodeTips = false;
                axiosPost(GETVERCODE, qs.stringify({phone:this.user.telPhNum}))
                .then((res) => {
                    console.log("获取验证码成功", res)
                }).catch(err => {
                    console.log("获取验证码失败 ", err)
                })
            }
        },
        ForgetPassWordgetVerCode() {
            if(this.user.telPhNum.length < 11) {
                return
            } 
            if(this.verCodeTips){
                this.verCodeTip  = this.countDown + "秒后重新获取"
                this.timer = setInterval(() => {
                this.countDown--;
                this.verCodeTip  = this.countDown + "秒后重新获取"
                if(this.countDown == -1) {
                    this.verCodeTips = true;
                    this.verCodeTip = "重新获取";
                    this.countDown = 60;
                    clearInterval(this.timer)
                }
                }, 1000)
            } else {
                return
            }
            if(this.verCodeTips) {
                this.verCodeTips = false;
                if(this.setPwdFrom == "userChangeTel") {
                    axiosPost(CHANGTELGETVERCODE, qs.stringify({phone: this.user.telPhNum}))
                    .then(res => {
                        console.log("更换手机号获取验证码成功")
                        this.$message.success('已发送')
                    }).catch(err => {
                        console.log("更换手机号验证码获取失败", err)
                    })
                }else {
                    console.log(this.setPwdFrom, this.user.telPhNum)
                    axiosPost(FORGETPWDGETVERCODE, qs.stringify({phone:this.user.telPhNum}))
                    .then((res) => {
                        console.log("获取验证码成功", res)
                    }).catch(err => {
                        console.log("获取验证码失败 ", err)
                    })
                }
               
            }
        },
        changeTelGetVerCode(str) {
            if(str == 'telPhNum') {
                if(this.user.telPhNum.length < 11) {
                return
                }
            }
            if(this.verCodeTips){
                this.verCodeTip  = this.countDown + "秒后重新获取"
                this.timer = setInterval(() => {
                    this.countDown--;
                    this.verCodeTip  = this.countDown + "秒后重新获取"
                    if(this.countDown == -1) {
                        this.verCodeTips = true;
                        this.verCodeTip = "重新获取";
                        this.countDown = 60;
                        clearInterval(this.timer)
                    }
                }, 1000)
            } else {
                return
            }
            if(this.verCodeTips) {
                let phone;
                if(str == 'usedTelNum') {
                    phone = this.user.usedTelNum;
                } else {
                    phone = this.user.telPhNum
                }
                axiosPost(CHANGTELGETVERCODE, qs.stringify({phone: phone}))
                .then(res => {
                    console.log("更换手机号验证码获取成功", res);
                }).catch(err => {
                    console.log("更换手机号验证码获取失败", err)
                })
            }
        },
        getUserInformation() {
            axiosGet(GETUSERINFORMATION)
                .then(res => {
                    set_uid(res.data.uid);
                    set_start();
                }).catch(err => {
                    // 
                    // this.$message.error(err.data.msg);
                })
        },
        jumpLastRouter() {
            // 更换手机号取消身份验证 -》放弃修改手机号
            this.$router.back();
        },
        clearTelPhNum() {
            this.user.telPhNum = "";
        },
        changeAgreeTop() {
            // 是否同意协议
            this.agreeTip = !this.agreeTip;
            if(this.router == 0) {
                if(this.user.telPhNum.length == 11  && this.user.verCode.length == 6 &&  this.agreeTip) {
                    this.submitState = true;
                } else {
                    this.submitState = false;
                }
            }
        },
        clearPassword() {
            this.user.passWord = "";
        },
        changePassWordType() {
            this.passwordTip = !this.passwordTip;
            if(this.passwordTip) {
                document.getElementById("loginPassWord").type = "text";
            } else {
                document.getElementById("loginPassWord").type = "password";
            }
        },
        jumpLoginStep(index, setPwdFrom) {
            if(index == 0 || index == 1) {
                this.loginTitle = "登录";
                this.$router.push({path: '/login?index=' + index});
            }else if(index == 2){
                if(setPwdFrom != undefined) {
                    this.setPwdFrom = setPwdFrom;
                    if(this.setPwdFrom.indexOf("user") > -1) {
                        this.loginTitle = "身份验证";
                    }else if(this.setPwdFrom.indexOf("login") > -1) {
                        this.loginTitle = "忘记密码";
                    }
                    this.$router.push({path: '/login?index=2&setPwdFrom=' + this.setPwdFrom});
                }
            }else if(index == 3) {
                this.setPwdFrom = setPwdFrom;
                if(this.setPwdFrom.indexOf('SetPwd') > -1) {
                    this.loginTitle = "设置密码";
                }else {
                    this.loginTitle = "修改密码";
                }
                this.$router.push({path: '/login?index=3&setPwdFrom=' + this.setPwdFrom});
            }else if(index == 4){
                this.loginTitle = "";
                this.$router.push({path: '/login?index=4&setPwdFrom=' + this.setPwdFrom});
            }else if(index == 5){
                this.loginTitle = "";
                this.$router.push({path: '/login?index=5&setPwdFrom=' + this.setPwdFrom});
            }else if(index == 11) {
                this.loginTitle = "";
            }
            this.router = index;
        },
        closeLogin() {
            if(localStorage.getItem("x") != null)
            localStorage.removeItem("x")
            if(localStorage.getItem("loginFrom") == null){
                this.$router.push({path: '/home'})
            } else {
                this.$router.push({path: localStorage.getItem("loginFrom")})
            }
            
        },
    }
}
</script>

<style lang="scss" scoped>
.login-content{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #F6F6F6;
    .total-header-login{
        text-align: center;
        height: 44px;
        background: #F9F9F9;
        // background: #ffacac;
        padding: 0 20px;
        .close-login{
            display: inline-block;
            position: absolute;
            left: 20px;
            i{
                line-height: 44px;
                font-size: 14px;
            }
        }
        .login-title{
            line-height: 44px;
            img{
                width: 24px;
                margin-right: 6px;
            }
            span{
                font-family: 'PangMenZhengDao';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .loginTitle{
        margin-top: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.128571px;
        color: #333333;
    }
    .funcContainer{
        padding: 0 39px;
        margin-top: 36px;
        .telPhNum{
            height: 26px;
            padding-bottom: 4px;
            // background: #D36666;
            border-bottom: 1px solid #e5e5e5;
            span:nth-child(1) {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.0857143px;
                color: #000000;
                // background: #000;
            }
            span:nth-child(2) {
                margin: 0 7px;
                color: #e5e5e5;
            } 
            input{
                border: none;
                outline: none;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 128.5%;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.8);
            }
            input::-webkit-input-placeholder {
                color: #b8b8b8;
            }
            i{
                float: right;
                color: rgba(0, 0, 0, 0.6);
                cursor: pointer;
            }
        }
        .verCode{
            margin-top: 32px;
            border-bottom: 1px solid #e5e5e5;
            input{
                border: none;
                outline: none;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 128.5%;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.8);
            }
            input::-webkit-input-placeholder {
                color: #b8b8b8;
            }
            span{
                float: right;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.0857143px;
                cursor: pointer;
            }
        }
        .passWord{
            margin-top: 32px;
            border-bottom: 1px solid #e5e5e5;
            input{
                border: none;
                outline: none;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 128.5%;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.8);
            }
            input::-webkit-input-placeholder {
                color: #b8b8b8;
            }
            span{
                float: right;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.0857143px;
                cursor: pointer;
            }
        }
        .returnErrReason{
            height: 13px;
            margin-top: 4px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 131%;
            letter-spacing: 0;
            color: #D36666;
        }
        .password-grade{
            color: #666666;
        }
        .tips-prompt{
            margin-top: 16px;
            display: flex;
            i{
                display: inline-block;
                height: 20px;
                margin-top:  -5px;
                margin-right:  5px;
            }
            div{
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.03em;
                color: rgba(0, 0, 0, 0.4);
                span{
                    color: #5490F4;
                }
            }
        }
        .loginReg-container{
            margin-top: 48px;
            .loginRegButton{
                letter-spacing: 0;
                width: 100%;
                height: 44px;
                margin-left: 0px !important;
                margin-top: 0px !important;
                border-radius: 24px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 44px;
                text-align: center;
                color: #FFFFFF;
                background: #000000 !important;
            }
            .cantsubmit{
                color: #999999;
                background: #E5E5E5 !important;
            }
        }
        .buttonBottom{
            margin-top: 8px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 131%;
            text-align: center;
            letter-spacing: 0.025em;
            color: rgba(0, 0, 0, 0.8);
            span:nth-child(1){
                margin: 0 14px 0 0;
            }
            span:nth-child(3){
                margin: 0 0px  0 14px;
            }
            span:nth-child(2){
                margin: 0 14px;
            }
        }
    }
    .setPassWord-success{
        .password-tip{
            margin-top: 94px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #000000;
            opacity: 0.8;
        }
        .setPwdSuccess{
            margin-top: 16px;
            text-align: center;
            img{
                width: 204px;
            }
        }
        .button{
            margin: 94px 44px 0;
            width: calc(100vw - 88px);
            height: 44px;
            background: #000000;
            border-radius: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 44px;
            text-align: center;
            color: #FFFFFF;
        }
        // .button-container{
            
        //     .loginRegButton{
        //         letter-spacing: 0;
        //         width: 100%;
        //         height: 38px;
        //         margin-left: 0px !important;
        //         margin-top: 0px !important;
        //         border-radius: 24px;
        //         font-family: PingFang SC;
        //         font-style: normal;
        //         font-weight: 500;
        //         font-size: 16px;
        //         line-height: 38px;
        //         text-align: center;
        //         color: #FFFFFF;
        //         background: #000000 !important;
        //     }
        //     .cantsubmit{
        //         color: #999999;
        //         background: #E5E5E5 !important;
        //     }
        // }
    }
    .contactUS{
        .contactUS-title{
            margin-top: 48px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.128571px;
            color: #333333;
        }
        .contactUS-img{
            margin: 50px auto;
            text-align: center;
            img{
                width: 150px;
            }
        }
    }
    .logOutSystem{
        .logOutSystem-title{
            margin-top: 121px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.128571px;
            color: #333333;
        }
        .logOutSystem-button{
            margin-top: 133px;
            text-align: center;
            span{
                display: inline-block;
                width: 139.5px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 44px;
                text-align: center;
                letter-spacing: 0;
                color: #000000;
                background: #fff;
                border-radius: 24px;
                border: 1px solid #000000;
                height: 44px;
            }
            span:nth-child(1) {
                color: #fff;
                background: #000;
                border: 1px solid #fff;
                margin-right: 8px;
            }
        }
    }
    
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
</style>


<style>
/* 按钮阴影消失 */
.loginReg-container .v-btn--is-elevated{
  box-shadow: none;
}
.loginReg-container .v-btn--is-elevated{
  box-shadow: none;
}
.loginReg-container .v-btn--is-elevated{
  box-shadow: none;
}
.loginReg-container .v-btn--is-elevated{
  box-shadow: none;
}


.confirmTelPhone .v-btn--is-elevated{
  box-shadow: none;
}
.confirmTelPhone .v-btn--is-elevated{
  box-shadow: none;
}
.confirmTelPhone .v-btn--is-elevated{
  box-shadow: none;
}
.confirmTelPhone .v-btn--is-elevated{
  box-shadow: none;
}
</style>