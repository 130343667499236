<template>
    <div class="tab-content">
        <div class="content-background"></div>
        <div class="tab-container">
            <p class="close-tabContainer">
                <i class="iconfont icon-guan" @click="closeTabContainer"></i>
            </p>
            <div class="tab-router">
                <p v-for="(item, index) in routerArr" :key="index" @click="jumpRouter(index)" :class="index == tabIndex ? 'selected' : ''">
                    <i v-if="tabIndex != index" :class="item.className"></i>
                    <i v-if="tabIndex == index" :class="item.selectedName" class="selected"></i>
                    {{item.router}}     
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data:() =>({
        routerArr: [
            {
                className: "iconfont icon-icon_leftbar_home_unselect_241",
                selectedName: "iconfont icon-icon_leftbar_home_select_241",
                router: "首页"
            },
            {
                className: "iconfont icon-icon_leftbar_collect_unselect_241",
                selectedName: "iconfont icon-icon_leftbar_collect_select_241",
                router: "收藏"
            },
            {
                className: "iconfont icon-icon_leftbar_feedback_unselect_241",
                selectedName: "iconfont icon-icon_leftbar_feedback_select_241",
                router: "联系我们"
            },
            {
                className: "iconfont icon-gerenzhongxin",
                selectedName: "iconfont icon-a-daohanggerenzhongxin",
                router: "登录/注册"
            }
        ],
        tabIndex:  0
    }),
    methods: {
        closeTabContainer() {
            this.$emit("closeTabContainer");
        },
        jumpRouter(index) {
            if(this.tabIndex == 0 || this.tabIndex == 1) {
                this.$emit("destoryWave")
            }
            this.tabIndex = index;
            if(this.tabIndex == 0) {
                this.$router.push({path: '/'});
            }else if(this.tabIndex == 1) {
                this.comofireLogin("/collection")
            }else if(this.tabIndex == 2) {
                this.$router.push({path: '/contact'});
            }
            else if(this.tabIndex == 3) {
                this.comofireLogin("/user");
            }
            this.$emit("closeTabContainer");
        },
        comofireLogin(str) {
            localStorage.setItem("loginFrom",str)
            if(localStorage.getItem("token") == null) {
                this.$router.push({path: '/login?index=0'});
            } else if(localStorage.getItem("token") != null) {
                this.$router.push({path: str});
            }
        },
        comfireTabIndex() {
            if(localStorage.getItem("token") == null) {
                this.routerArr[3].router = "登录";
            } else if(localStorage.getItem("token") != null) {
                this.routerArr[3].router = "个人中心";
            }
            let href = window.location.href;
            if(href.indexOf('/home') > -1){
                this.tabIndex = 0;
            }else if(href.indexOf('/collection') > -1){
                this.tabIndex = 1;
            }else if(href.indexOf('/contact') > -1){
                this.tabIndex = 2;
            }
            else if(href.indexOf('/login') > -1 || href.indexOf('/user') > -1){
                this.tabIndex = 3
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tab-content{
    position: relative;
    z-index: 1000;
    width: 100%;
    height: 100%;
    .content-background{
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
    }
    .tab-container{
        position: absolute;
        top: 0;
        right: 0; 
        width: 66.7%;
        height: 100%;
        background: #F6F6F6;
        .close-tabContainer{
            text-align: right;
            height: 39px;
            line-height: 50px;
            margin-top: 10px;
            padding-right: 20px;
            i{
                color: rgba(0, 0, 0, 0.8);
            }
        }
        .tab-router{
            margin-top: -10px;
            p{
                height: 50px;
                margin-bottom: 11px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 50px;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.7);
                i{
                    color: #333;
                    font-size: 20px;
                    margin: 0 8px 0px 18px;
                }
                .selected{
                    color:  rgba(0, 0, 0, 0.8);
                }
            }
        }
    }
}
</style>
