<template>
    <div class="home-content" id="home-content">
        <div class="home-title">
            <div class="title">BGM猫</div>
            <div class="explain"> 一键生成视频/播客配乐和片头音乐</div>
        </div>
        <div class="home-duration">
            <div class="content-tab">
                <div class="tab-word">
                    <span :class="showBurstPoint ? 'choosedMusic' : ''" @click="changeShowBurstPoint(0)">视频配乐</span>
                    <span :class="showBurstPoint ? '' : 'choosedMusic'" @click="changeShowBurstPoint(1)">片头音乐</span>
                </div>
                <div :style="showBurstPoint ? 'left: 30px;' : 'left: 140px;'" class="tip-prompt"></div>
            </div>
            <div class="duration-content">
                <div>
                    <p>输入时长</p>
                    <p v-if="showBurstPoint">[30秒～300秒]</p>
                    <p v-if="!showBurstPoint">[1秒～30秒]</p>
                </div>
           
                <input
                    v-on:keydown="inspectDuration($event)"
                    v-if="showBurstPoint"
                    class="inputDuration"
                    placeholder="0"
                    v-model="duration1"
                    onfocus="this.placeholder=''"
                    onblur="this.placeholder='0'"
                    type="Number"
                />
                <input
                    v-on:keydown="inspectDuration($event)"
                    v-if="!showBurstPoint"
                    class="input-duration"
                    v-model="duration2"
                    placeholder="0"
                    onfocus="this.placeholder=''"
                    onblur="this.placeholder='0'"
                    type="Number"
                />
                 <!-- v-if="!showBurstPoint" -->
                <span>秒</span>
            </div>
        </div>
        <div class="home-userInput">
            <div class="home-inputArea" :style="hoverInputOrLabel == 1 ? 'opacity:0.4;' : ''">
                <div class="input-container">
                    <div class="borderBox" >
                        <i class="iconfont icon-duihao" 
                            :style="hoverInputOrLabel == 0  ? 'opacity:1;' : 'opacity: 0;'">
                        </i>
                    </div>
                    <span>输入描述</span>
                    <div class="describe-input">
                        <textarea type="text" placeholder="写下你的创意" 
                            v-model="inputContent" 
                            autocomplete="off"
                            @focus="inputFocus()"
                            ref="inputContent"
                            id="inputContent"></textarea>
                    </div>
                </div>
            </div>
            <div class="home-labelArea"  :style="hoverInputOrLabel == 0 ? 'opacity:0.4;' : ''">
                <div class="borderBox" >
                    <i class="iconfont icon-duihao" 
                        :style="hoverInputOrLabel == 1 ? 'opacity:1;' : 'opacity: 0;'">
                    </i>
                </div>
                <span>选择标签</span>
                <div class="choosedLabel" :style="choosedArr.length > 0 ? '' : 'height: 28px;'">
                    <div class="choosedLabel-number">
                        <i class="iconfont icon-icon_label_20"></i>
                        <span>
                            <span :style="choosedArr.length > 0 ? 'color: #999;' : ''">{{choosedArr.length}}</span>/3
                        </span>
                    </div>
                    <div class="choosedLabel-content">
                        <p class="nothing-choosed" v-if="choosedArr.length == 0">#请选择感兴趣的标签</p>
                        <div class="choosedlabel-content-container" v-if="choosedArr.length > 0">
                            <div class="choosedlabel-content" v-for="(item, index) in choosedArr"
                                :key="index">
                                <span class="justContent">{{item}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="label-content">
                    <a-tabs default-active-key="1">
                        <a-tab-pane class="labelArr sceneArr-content" key="1" tab="场景">
                            <span class="transmitStyle" v-for="(item, index) in sceneArr"
                            :key="index" 
                            :class="choosedArr.indexOf(item) > -1 ? 'sceneStyle' : ''"
                            @click="chooseStyle(1, item)">
                            {{item}}
                            </span>
                        </a-tab-pane>
                        <a-tab-pane class="labelArr genreArr-content" key="2" tab="风格">
                            <span class="transmitStyle" v-for="(item, index) in genreArr"
                            :key="index"
                            :class="choosedArr.indexOf(item) > -1 ? 'genreStyle' : ''"
                            @click="chooseStyle(2, item)">
                            {{item}}
                            </span>
                        </a-tab-pane>
                        <a-tab-pane class="labelArr moodArr-content" key="3" tab="心情" force-render>
                            <span  class="transmitStyle" v-for="(item, index) in moodArr"
                            :key="index" 
                            :class="choosedArr.indexOf(item) > -1 ? 'moodStyle' : ''"
                            @click="chooseStyle(3, item)">
                            {{item}}
                            </span>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div> 
        </div>
        <div class="home-generation">
            <v-btn class="ma-2 createButton" 
                :class="buttonState ? '' : 'cantcreate'" 
                :loading="loading" 
                :disabled="loading" 
                color="secondary"
                @click="createMusic">
                生成
            </v-btn>
        </div>
        <div id="contentBox" style="height:100px;display:none;"></div>
        <div class="home-bottom">
            <div class="home-player" v-show="audioShow">
                <player
                    ref="player"
                    :wavesurferAdr="wavesurferAdr" 
                    :burstPointTime="burstPointTime"
                    :musicId="musicId"
                    @loadFinish="loadFinish">
                </player>
            </div>
            <div class="home-beian" >
                <a class="beianhao" style="color: #B8B8B8;" href="https://beian.miit.gov.cn" target="_blank">京ICP备19028267号-2</a>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import Player from "./player"
import {axiosGet, axiosPost} from "../../src/axios.js"
import {LABELARR, FILEINFO, SOUNDTRACK} from "../../src/api.js"
import { data_process_new } from "../../src/utils/util.js";
import { send_record } from "../utils/umeng_util"
export default {
    components: {
        Player
    },
    data: () => ({


        duration1: 0,
        duration2: 0,
        showBurstPoint: true, // 视频配乐/片头音乐

        hoverInputOrLabel:  -1,
        inputContent: "",

        genreArr:[], // 风格数组
        moodArr: [], // 心情数组
        sceneArr: [], // 场景数组
        choosedArr: [], // 被选择的数组

        loading: false, // 生成按钮的加载状态
        buttonState: false, // 生成按钮可点击状态
        audioShow: false, // 播放器是否显示
        

        burstPointTime: -1,  // 爆点
        musicId: -1, // 音频ID
        wavesurferAdr: "", // 音频地址

        structureTimeArr: [],
        structurePowerArr: [],
        burstConArr:[], // 控制高能点范围
        showBurstPoint: true, // 控制高能点生成
        localBurstArr: [], // 上次高能点段落
        localFirstBurst: true, // 是否第一次生成带高能点的音频
        localLabelId: 0, // 上次歌曲id
        localPhraseTime: 0, // 段长
        beforePhraseTime: 0, // 处理前段长
        localBurstIndex: 0, // 高能点在序列中所处的位置
        localburstPointTime: 0, // 初始高能点时间
        localPhraseBeforeBurst: 0, // 高能点前段落长度
        offsetSpeed: 1,  // 速度变化
        offsetPhrase: 0,  // 段落长度变化
        startHalf: 0,


    }),
    watch: {
        duration1: function(val, oldVal) {
            if(this.showBurstPoint) {
                if(val.length == 2 && val[0] == 0 && val[1] != 0) {
                    this.duration1 = val[1]
                }
                if(val.length == 3 && val[0] == 0 && val[1] == 0 && val[2] != 0) {
                    this.duration1 = val[2]
                }
                if(val.length == 3 && val[0] == 0 && val[1] == 0 && val[2] == 0) {
                    this.duration1 = 0
                }

                if(val > 300) {
                    this.duration1 = 300;
                }
                if(this.duration1 >= 30) {
                    if(this.hoverInputOrLabel) {
                        if(this.choosedArr.length > 0)
                        this.buttonState = true;
                        else this.buttonState = false;
                    } else {
                        if(this.inputContent.length > 0)
                        this.buttonState = true;
                        else this.buttonState = false;
                    }
                } else {
                    this.buttonState = false;
                }
            }
        },
        duration2: function(val, oldVal) {
            if(!this.showBurstPoint) {
                if(val.length >= 2) {
                    this.duration2  = val.slice(0,2)
                }
                if(val > 30) {
                    this.duration2 = 30;
                } 

                if(this.duration2 > 0) {
                    if(this.hoverInputOrLabel) {
                        if(this.choosedArr.length > 0)
                        this.buttonState = true;
                        else this.buttonState = false;
                    } else {
                        if(this.inputContent.length > 0)
                        this.buttonState = true;
                        else this.buttonState = false;
                    }
                } else {
                    this.buttonState = false;
                }
            }
        },
        inputContent: function(val, oldVal) {
            if(val.length > 0) {
                if(this.showBurstPoint) {
                    if(this.duration1 >= 30) 
                    this.buttonState = true;
                    else this.buttonState = false;
                } else {
                    if(this.duration2 > 0) 
                    this.buttonState = true;
                    else this.buttonState = false;
                }
            } else {
                this.buttonState = false;
            }
        },
        wavesurferAdr: function(val, oldVal) {
            if(oldVal.length == 0) {
                document.getElementById("contentBox").style.display = "block"
            }
        },
    },
    filters: {
       
    },
    beforeMount() {
    },
    mounted() {
        if(sessionStorage.getItem("wavesurferAdr") != null) {
            this.wavesurferAdr = sessionStorage.getItem("wavesurferAdr");
            this.burstPointTime = sessionStorage.getItem("burstPointTime");
            this.musicId = sessionStorage.getItem("musicId");
            this.audioShow = true;
            sessionStorage.removeItem("wavesurferAdr")
            sessionStorage.removeItem("burstPointTime")
            sessionStorage.removeItem("musicId")
        }
        this.getStyleArr();
        this.autoTextarea(document.getElementById("inputContent"))
    },
    methods: {
        getBrowserInterfaceSize() {
            var pageHeight = window.innerHeight;
            if (typeof pageWidth != "number") {
                //在标准模式下面
                if (document.compatMode == "CSS1Compat" ) {
                    pageHeight = document.documentElement.clientHeight;
                } else {
                    pageHeight = window.body.clientHeight;
                }
            }
            this.$nextTick(()=> {
                console.log(document.getElementsByClassName("home-content")[0])
                document.getElementsByClassName("home-content")[0].style.minHeight = pageHeight + 'px';
            })
            
        },
        changeShowBurstPoint(tip) {
            if(!tip) {
                this.showBurstPoint = true;
                if(this.duration1 >= 30){
                    if(this.hoverInputOrLabel) {
                        if(this.choosedArr.length > 0) {
                            this.buttonState = true;
                        } else {
                            this.buttonState = false;
                        }
                    } else {
                        if(this.inputContent.length > 0) {
                            this.buttonState = true;
                        }else {
                            this.buttonState = false;
                        }
                    }
                } else {
                    this.buttonState = false;
                }
            }else {
                this.showBurstPoint = false;
                if(this.duration2 > 0){
                    if(this.hoverInputOrLabel) {
                        if(this.choosedArr.length > 0) {
                            this.buttonState = true;
                        } else {
                            this.buttonState = false;
                        }
                    } else {
                        if(this.inputContent.length > 0) {
                            this.buttonState = true;
                        }else {
                            this.buttonState = false;
                        }
                    }
                } else {
                    this.buttonState = false;
                }
            }
        },
        // 生成音乐
        createMusic(){
            if (!this.buttonState) {
                return
            }
            
            let Duration;
            if(this.showBurstPoint) {
                Duration = this.duration1
            } else {
                Duration = this.duration2
            }
            // 开始计算，重置数据
            this.offsetSpeed = 1
            this.localBurstArr = []
            this.localFirstBurst = true
            this.startHalf = 0
            this.offsetPhrase = 0,  // 段落长度变化
            // 重置按钮情况
            this.loading = true
            this.buttonState = false
            let style = this.choosedArr
            let fileinfo_data

            if(this.hoverInputOrLabel == 0) {
                fileinfo_data = {
                text: this.inputContent,
                duration: Duration
                }
            } else if(this.hoverInputOrLabel == 1) {
                fileinfo_data = {
                label: style,
                duration: Duration
                }
            }
            if (this.showBurstPoint){
                fileinfo_data['boom'] = 1;
            }
            let templateId = localStorage.getItem("templateId")
            fileinfo_data['exclude_tid'] = templateId == null ? null : templateId.split(",");
            axiosPost(FILEINFO, qs.stringify(fileinfo_data, { indices: false })).then((res) => {
                localStorage.setItem("templateId", localStorage.getItem("templateId") == null ? res.data._tid : localStorage.getItem("templateId") + ',' + (res.data._tid))
                let datas
                if (this.showBurstPoint){
                    if ('boom_list_data' in res.data){
                        
                        let success = this.createMusicWithBurstPoint(Duration, res)
                        if (success==-1){
                            this.showBurstPoint = false
                            this.$message.warning("不满足生成高能点条件，已关闭高能点")
                            datas = this.data_process(Duration, res)
                            this.getAudio(datas)
                        }
                        send_record("generate_generate_music_button",
                            {
                                generate_type: this.showBurstPoint ? '视频配乐' : '片头音乐',
                                text_type: this.hoverInputOrLabel ? 'label' : 'text',
                                duration: Duration
                            }
                        )

                    }else{
                        this.showBurstPoint = false
                        this.createMusic()
                        return
                    }
                }else{
                    datas = this.data_process(Duration, res)
                    this.getAudio(datas)
                }
            }).catch((err) => {
                console.log(err);
                this.loading = false;
                this.buttonState = true;
                // this.$message.warning("生成错误");
            }).finally((fin)=>{
                // console.log(fin);
            })
        },
        // 计算过程
        data_process(duration, res){
            let jsnx = require('jsnetworkx');
            let SAMPLE_RATE = 44100
            let _bpm, _beat_per_phrase, nodes ,links ,_ending_slices,_tid  // 需要的后端传参
            let phrase_start, phrase_end, p_num=0, iter_lists = [['S']], curr_pointer = [0], dead_end = false, _phrase_slices={}// 计算过程中的数据
            let _beats, _bars, _phrases, curr_node, next_list, _graph// 计算过程中的数据
            let phrases=[]  // 计算结束的数据

            _bpm = res.data._bpm
            _beat_per_phrase = res.data._beat_per_phrase
            _ending_slices = res.data._ending_slices
            _tid = res.data._tid
            this.localLabelId = _tid
            nodes = res.data.nodes
            links = res.data.links

            // 主体乐句数据准备
            _beats = Math.floor(_bpm / 60 * duration)
            _bars = Math.floor(_beats/4)
            if (_beats % 4 == 0) {
                _bars -= 1 
            }
            _phrases = Math.floor(_bars / (_beat_per_phrase / 4))
            sample_per_phrase=Math.floor(Math.round(SAMPLE_RATE * 60 / _bpm * _beat_per_phrase))
            let sample_per_phrase = Math.floor(Math.round(SAMPLE_RATE * 60 / _bpm * _beat_per_phrase))
            let nodes_slice = nodes.slice(1,-2)
            for (let i = 0; i < nodes_slice.length; i++) {
                phrase_start = Math.floor(Math.round(i * sample_per_phrase))
                phrase_end = Math.floor(Math.round((i + 1) * sample_per_phrase))
                if (nodes_slice[i] in _phrase_slices) {
                    _phrase_slices[nodes_slice[i]].push([phrase_start, phrase_end])
                } else {
                    _phrase_slices[nodes_slice[i]] = [[phrase_start, phrase_end]]
                } 
            }
            p_num = _phrases
            _graph = new jsnx.DiGraph()
            _graph.addEdgesFrom(links)
            let re_calc = true
            let re_calc_num = 0
            // console.log(p_num);
            // 搜索路径
            while (re_calc && re_calc_num<20) {
                if (p_num > 0){
                    while(phrases.length <= p_num && iter_lists.length > 0){
                        // 选择节点
                        curr_node = iter_lists[iter_lists.length-1][curr_pointer[curr_pointer.length-1]]
                        phrases.push(curr_node)
                        if (phrases.length <= p_num){
                        // 导入下一节点路径
                        next_list = _graph.successors(curr_node)
                        if (next_list.length==0) {
                            dead_end = true
                        }else{
                            dead_end = false
                        }
                        if (!dead_end){
                            if (!Array.prototype.derangedArray) {
                            Array.prototype.derangedArray = function() {
                                for(var j, x, i = this.length; i; j = parseInt(Math.random() * i), x = this[--i], this[i] = this[j], this[j] = x);
                                return this;
                            };
                            }
                            next_list.derangedArray()  //随机打乱顺序
                            iter_lists.push(next_list)
                            curr_pointer.push(0)
                        }
                        }
                        if (dead_end || (phrases.length > p_num && _graph.successors(curr_node).indexOf('E')==-1)){
                        phrases.pop()
                        while (iter_lists.length > 0 && curr_pointer[curr_pointer.length-1] >= iter_lists[iter_lists.length-1].length - 1){
                            phrases.pop()
                            iter_lists.pop()
                            curr_pointer.pop()
                        }
                        if (curr_pointer.length > 0){
                            let tmp_ = curr_pointer.pop()
                            curr_pointer.push(tmp_ + 1)
                        }
                        }
                    }
                    let old_phrases = this.checkOld(_tid, phrases)
                    if(old_phrases==true){
                        re_calc_num += 1
                    }else{
                        old_phrases.push(phrases)
                        localStorage.setItem(_tid,JSON.stringify(old_phrases));
                        re_calc = false
                    }
                }else{
                    re_calc_num += 1
                }   
                if (iter_lists.length == 0){
                    // console.log('没有合适的队列');
                    this.loading = false;
                    this.buttonState = true
                    return -1
                }
            }

            let ending_slice
            if (phrases.length==0) {
                _ending_slices.derangedArray(); //随机打乱顺序
                ending_slice =  _ending_slices[0]
            }else{
                ending_slice = phrases[phrases.length-1]
            }
            this.localPhraseTime = 60 / _bpm * _beat_per_phrase
            this.beforePhraseTime = this.localPhraseTime
            let datas = {
                "_bpm": _bpm,
                "beat_per_phrase": _beat_per_phrase,
                "ending": ending_slice,
                "duration": duration,
                "_tid": _tid,
            }
            datas['slices'] = JSON.stringify(_phrase_slices)
            datas['phrases'] = phrases
            return datas
        },
        getAudio(datas) {
            let phrases = datas['phrases']
            datas['startHalf'] = this.startHalf
            if(this.hoverInputOrLabel == 0) {
                datas['text'] = this.inputContent;
            } else if(this.hoverInputOrLabel == 1) {
                datas['label'] = this.choosedArr
            }
            axiosPost(SOUNDTRACK,  
                qs.stringify(datas, { indices: false })
            ).then(res=> {
                this.audioShow = true
                this.structureTimeArr=[0]
                this.wavesurferAdr = res.data.path
                this.musicName = res.data.musicName
                this.musicId = res.data.musicId
                this.$refs.player.changeCollectionTipFalse()
                if (this.showBurstPoint){
                    let havePower = false
                    let phrases_split, power_list=[], boom_list=[]
                    for (let i = 1; i < phrases.length; i++) {
                        phrases_split = phrases[i].split("_")
                        this.structureTimeArr.push(this.localPhraseTime * (i-1))
                        if (phrases_split.length==3) {
                            havePower = true
                            power_list.push(phrases_split[1])
                        }
                    }
                    for (let i = 1; i < power_list.length; i++) {
                        boom_list.push(power_list[i]-power_list[i-1])
                    }
                    if(havePower){
                        let max_index = this.getMaxIndex(boom_list)
                        this.burstPointTime = this.localPhraseTime*(max_index+1)
                        this.localBurstArr = [phrases[max_index+1], phrases[max_index+2]]
                        this.localBurstIndex = max_index
                    }
                }else{
                    this.burstPointTime = -1
                }
                this.localFirstBurst = false
                this.setCookie("createAudioNumber", this.createAudioNumNoLogin - 1);
            }).catch(err => {
                this.buttonState = true
                this.loading = false;
                this.$message.warning(err.data.msg);
            })
        },
        createMusicWithBurstPoint(duration, res){
            let this_data = {
                offsetSpeed: this.offsetSpeed,
                localLabelId: this.localLabelId,
                localBurstArr: this.localBurstArr,
                localFirstBurst: this.localFirstBurst,
                showBurstPoint: this.showBurstPoint,
                localBurstIndex: this.localBurstIndex,
                localPhraseTime: this.localPhraseTime,
                beforePhraseTime: this.beforePhraseTime,
                localPhraseBeforeBurst: this.localPhraseBeforeBurst,
                startHalf: this.startHalf
            };
            try {
                let datas = data_process_new(duration, res, this_data);
                this_data = datas["this_data"];
                Object.keys(this_data).forEach((key) => {
                this[key] = this_data[key];
                });
                this.getAudio_new(datas["datas"]);
            } catch (err) {
                this.buttonState = true;
                this.loading = false;
                throw err
            }
        },
        getAudio_new(datas) {
            datas['offset_speed'] = this.offsetSpeed
            this.burstPointTime = (this.localPhraseBeforeBurst+1) * this.localPhraseTime
            if (this.startHalf==1 ) {
                this.burstPointTime-=0.5*this.localPhraseTime
            }else if (this.startHalf==2) {
                this.burstPointTime+=0.5*this.localPhraseTime
            }
            datas['BurstTime'] = this.burstPointTime
            let phrases = datas['phrases']
            datas['startHalf'] = this.startHalf
            if(this.hoverInputOrLabel == 0) {
                datas['text'] = this.inputContent;
            } else if(this.hoverInputOrLabel == 1) {
                datas['label'] = this.choosedArr
            }
            axiosPost(SOUNDTRACK,  
                qs.stringify(datas, { indices: false })
            ).then(res=> {
                this.audioShow = true
                this.structureTimeArr=[]
                this.structurePowerArr=[]
                this.wavesurferAdr = res.data.path
                this.musicName = res.data.musicName
                this.musicId = res.data.musicId
                this.$refs.player.changeCollectionTipFalse()
                let phrases_split
                for (let i = 1; i < phrases.length; i++) {
                    phrases_split = phrases[i].split("_")
                    if (this.startHalf==1 || this.startHalf==2) {
                        if(i==1){
                            this.structureTimeArr.push(0)
                        }else{
                            this.structureTimeArr.push(this.localPhraseTime * (i-1)-this.localPhraseTime*0.5)
                        }
                    }else{
                        this.structureTimeArr.push(this.localPhraseTime * (i-1))
                    }
                    if (phrases_split.length==3) {
                        this.structurePowerArr.push(phrases_split[1][0]) // 取能量级的第一位
                    }
                }
                this.burstConArr=[this.beforePhraseTime,datas.duration*datas.offset_speed-this.beforePhraseTime]
                if (this.localFirstBurst) {
                    this.localburstPointTime = this.burstPointTime
                }
                this.localFirstBurst = false;
                this.setCookie("createAudioNumber", this.createAudioNumNoLogin - 1);
            }).catch(err => {
                this.$message.warning(err.data.msg);
                this.buttonState = true
                this.loading = false;
            })
        },
        getMaxIndex(arr) {
            var max = arr[0];
            //声明了个变量 保存下标值
            var index = 0;
            for (var i = 0; i < arr.length; i++) {
                if (max < arr[i]) {
                    max = arr[i];
                    index = i;
                }
            }
            return index;
        },
        // 获取标签函数
        getStyleArr() {
            axiosGet(LABELARR).then(res=> {
                this.genreArr = res.data.genre;
                this.moodArr = res.data.mood;
                this.sceneArr = res.data.scene;
            }).catch(err=> {
                this.$message.error(err.data.msg);
            })
        },
        loadFinish() {
            this.loading = false
            this.buttonState = true
        },
        // 300秒不允许输入负号
        inspectDuration(e) {
            if(e.keyCode == 189 || e.keyCode == 69) {
                e.preventDefault()
                event.preventDefault()
            }
        },
        // 选择风格
        chooseStyle(index, item) {
            this.hoverInputOrLabel = 1;
            let labelArr = index == 1 ? this.sceneArr : index == 2 ? this.genreArr : this.moodArr;
            let num = this.choosedArr.findIndex(items => items  ==  item);
            let sortIndex;
            if(num > -1) {
                this.choosedArr.splice(num, 1)
                if(this.choosedArr.length == 0) {
                    this.buttonState = false
                }
            } else {
                if(this.choosedArr.length == 0) {
                    this.choosedArr.push(item)
                }else{
                    for(let i = 0; i < this.choosedArr.length; i++) {
                        for(let j = 0; j < labelArr.length; j++) {
                            if(labelArr[j] == this.choosedArr[i]) {
                                sortIndex = i;
                                break
                            }
                        }
                    }
                    if(sortIndex > -1) {
                        this.choosedArr[sortIndex] = item;
                        this.$forceUpdate()
                    }else {
                        this.choosedArr.push(item)
                    }
                }
                
            }

            if(this.choosedArr.length > 0) {
                if(this.showBurstPoint) {
                    if(this.duration1 >= 30) {
                        this.buttonState = true;
                    }else {
                        this.buttonState = false;
                    }
                }else {
                    if(this.duration2 > 0) {
                        this.buttonState = true;
                    }else {
                        this.buttonState = false;
                    }
                }
            } else {
                this.buttonState = false;
            }
        },
        destoryWave() {
            this.$refs.player.destoryWave();
        },
        inputFocus() {
            this.hoverInputOrLabel = 0;
            if(this.showBurstPoint) {
                if(this.inputContent.length > 0 && this.duration1 >= 30) {
                  this.buttonState = true;
                } else {
                  this.buttonState = false;
                }
            }else {
                if(this.inputContent.length > 0 && this.duration2 > 0) {
                  this.buttonState = true;
                } else {
                  this.buttonState = false;
                }
            }
        },
        autoTextarea(elem, extra, maxHeight) {
            extra = extra || 0;
            var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
            isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
            addEvent = function (type, callback) {
                    elem.addEventListener ?
                            elem.addEventListener(type, callback, false) :
                            elem.attachEvent('on' + type, callback);
            },
            getStyle = elem.currentStyle ? function (name) {
            var val = elem.currentStyle[name];

            if (name === 'height' && val.search(/px/i) !== 1) {
                var rect = elem.getBoundingClientRect();
                return rect.bottom - rect.top -
                parseFloat(getStyle('paddingTop')) -
                parseFloat(getStyle('paddingBottom')) + 'px';        
            };

            return val;
            } : function (name) {
            return getComputedStyle(elem, null)[name];
            },
            minHeight = parseFloat(getStyle('height'));
            elem.style.resize = 'none';
            var change = function () {
            var scrollTop, height,
                padding = 0,
                style = elem.style;
    
            if (elem._length === elem.value.length) return;
            elem._length = elem.value.length;

            if (!isFirefox && !isOpera) {
                padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
            };
            scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            elem.style.height = minHeight + 'px';
            if (elem.scrollHeight > minHeight) {
                if (maxHeight && elem.scrollHeight > maxHeight) {
                height = maxHeight - padding;
                style.overflowY = 'auto';
                } else {
                height = elem.scrollHeight - padding;
                style.overflowY = 'hidden';
                };
                style.height = height + extra + 'px';
                scrollTop += parseInt(style.height) - elem.currHeight;
                document.body.scrollTop = scrollTop;
                document.documentElement.scrollTop = scrollTop;
                elem.currHeight = parseInt(style.height);
            };
            };
    
            addEvent('propertychange', change);
            addEvent('input', change);
            addEvent('focus', change);
            change();
        },
    }
}
</script>

<style lang="scss" scoped>
.home-content{
    width: 100vw;
    min-height: calc(100vh - 44px);
    padding: 16px 20px 0;
    .home-title{
        .title{
            font-family: PangMenZhengDao !important;
            font-style: normal;
            font-weight: 400;
            font-size: 28px !important;
            line-height: 32px;
            letter-spacing: 0.025em !important;
            color: #000000;
        }
        .explain{
            color: var(--60, rgba(0, 0, 0, 0.60));
            font-size: 14px;
            font-family: Songti SC;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.35px;
        }
    }
    .home-duration{
        margin-top: 24px;
        .content-tab{
            .tab-word{
                width: 196px;
                padding-right: 10px;
                height: 32px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                span{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: 0.02em;
                    color: #999999;
                    cursor: pointer;
                }
                .choosedMusic{
                    font-weight: 600;
                    color: #5490F4;
                }
            }
            .tip-prompt{
                width: 21px;
                height: 2px;
                background: #5490F4;
                position: relative;
                border-radius: 10px;
                top: -2px;
            }
        }
        .duration-content{
            margin-top: 16px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 14px;
            display: flex;
            div {
                padding-left: 12px;
                p:nth-child(1) {
                    margin-top: 14px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 25px;
                    text-align: justify;
                    letter-spacing: 0.05em;
                    color: rgba(0, 0, 0, 0.8);
                }
                p:nth-child(2) {
                    text-align: center;
                    margin-top: -20px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #999999;
                }
            }
            .inputDuration {
                margin-left: 24px !important;
                margin-top: 14px !important;
                text-align: center;
                outline: none;
                width: 60px;
                height: 39px;
                font-size: 32px;
                padding-bottom: 4px;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                background: #FFFFFF;
                cursor: pointer;
                color: transparent;
                text-shadow: 0 0 0 #434343;
                font-family: Alibaba PuHuiTi;
                font-style: normal;
                font-weight: normal;
                line-height: 49px;
                letter-spacing: 0.025em;
                color: #434343;
            }
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            input[type="number"] {
                -moz-appearance: textfield;
            }
            .input-duration{
                margin-left: 24px !important;
                margin-top: 14px !important;
                text-align: center;
                outline: none;
                width: 45px;
                height: 39px;
                font-size: 32px;
                padding-bottom: 4px;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                background: #FFFFFF;
                cursor: pointer;
                color: transparent;
                text-shadow: 0 0 0 #434343;
                font-family: Alibaba PuHuiTi;
                font-style: normal;
                font-weight: normal;
                line-height: 49px;
                letter-spacing: 0.025em;
                color: #434343;
            }
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            input[type="number"] {
                -moz-appearance: textfield;
            }
            span{
                margin-top: 38px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.025em;
                color: #BDBDBD;
            }
        }
    }
    .home-userInput{
        margin-top: 12px;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F7F7F7;
        border-radius: 14px;
        padding: 14px 12px !important;
        .home-inputArea{
            .input-container{
                .borderBox{
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border: 1.5px solid rgba(0, 0, 0, 0.6);
                    border-radius: 3px;
                    i{
                        color: #000000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 900;
                        position: relative;
                        top: -6px;
                        left: -2px;
                    }
                }
                span{
                    margin-left: 6px;
                    position: relative;
                    top: -6px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: 0.05em;
                    color: rgba(0, 0, 0, 0.8);
                }
            }
            
            .describe-input{
                margin-top: 10px;
                textarea{
                    width: calc(100vw - 64px);
                    color: rgba(0, 0, 0, 0.8);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    max-height: 100px;
                    outline: none;
                    resize: none;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-radius: 0 !important;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    display: block;
                    height: 20px;
                    overflow:hidden;
                }
                textarea::-webkit-input-placeholder{
                    color: rgba(0, 0, 0, 0.3);
                }
            }
        }
    }
    .home-labelArea{
        margin-top: 14px;
        .borderBox{
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 1.5px solid rgba(0, 0, 0, 0.6);
            border-radius: 3px;
            i{
                color: #000000;
                font-size: 16px;
                font-style: normal;
                font-weight: 900;
                position: relative;
                top: -6px;
                left: -2px;
            }
        }
        >span{
            margin-left: 6px;
            position: relative;
            top: -6px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.05em;
            color: rgba(0, 0, 0, 0.8);
        }
        .choosedLabel{
            width: calc(100vw - 64px);
            display: flex;
            color: #b8b8b8;
            .choosedLabel-number{
                i{
                    margin-right: 3px !important;
                }
                >span{
                    font-size: 12px;
                    position: relative;
                    top: -2px;
                }
            }
            .choosedLabel-content{
                margin-left: 10px;
                margin-top: 3px;
                .nothing-choosed{
                    margin-top: -3px;
                    height: 21px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 400;
                    display: flex;
                    letter-spacing: 0.025em;
                    color: #999999;
                }
                .choosedlabel-content-container{
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: -6px;
                    white-space: nowrap;
                    position: relative;
                    top: -4px;
                    .choosedlabel-content{
                        margin-top: 6px;
                        margin-right: 8px  !important;
                        height: 25px;
                        font-family: PingFang SC;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: #666666;
                    }
                    .justContent{
                      display: inline-block;
                      border-radius: 19px;
                      padding: 0px 10px;
                      height: 25px;
                      border: 1px solid #E5E5E5;
                    }
                    .or{
                      margin-left: 8px  !important;
                    }
                }
            }
        }
        .label-content{
            margin-top: -5px;
            .labelArr{
                .transmitStyle{
                    display:inline-block;
                    padding: 7px 14px !important;
                    border: 1px solid rgba(102, 102, 102, 0.5);
                    font-family: PingFang SC;
                    border-radius: 3px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: #666666;
                    margin-right: 12px !important;
                    margin-bottom: 8px;
                    cursor: pointer;
                }
                .genreStyle{
                    border: 1px solid #5490F4;
                    color: #5490F4;
                    font-weight: 600;
                    background: #FFFFFF;
                }
                .moodStyle{
                    border: 1px solid #5490F4;
                    color: #5490F4;
                    font-weight: 600;
                    background: #FFFFFF;
                }
                .sceneStyle{
                    border: 1px solid #5490F4;
                    color: #5490F4;
                    font-weight: 600;
                    background: #FFFFFF;
                }
            }
        }
    }
    .home-generation{
        position: relative;
        z-index: 100;
        width: 100vw;
        margin-left: -8px;
        margin-top: 12px;
        .createButton{
            width: calc(100% - 40px);
            height: 38px;
            font-size: 16px;
            background: #000 !important;
            border-radius: 20px;
            line-height: 22px;
        }
        .cantcreate{
            color: #999999;
            background: #E5E5E5 !important;
        }
        .custom-loader {
            animation: loader 1s infinite;
            display: flex;
        }
        @-moz-keyframes loader {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(360deg);
            }
        }
        @-webkit-keyframes loader {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(360deg);
            }
        }
        @-o-keyframes loader {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(360deg);
            }
        }
        @keyframes loader {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
    .home-player{
        width: 100vw;
        margin-left: -20px;
        position: fixed;
        height: 56px;
        bottom: 46px;
        z-index: 2;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    }
    .home-beian{
        z-index: 0;
        padding-top: 50px;
        width: calc(100vw - 5.33vw - 5.33vw);
        position: absolute;
        bottom: 8px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-family: PingFang SC;
    }
}
</style>


<style>
/* 选项卡 */
.label-content .ant-tabs-tab{
  padding: 0 !important;
  font-size: 14px;
  line-height: 35px;
  /* color: rgba(0, 0, 0, 0.3) !important; */
} 
.ant-tabs-tab:hover{
  color: #000 !important;
}
.label-content .ant-tabs-top-bar{
  width: 150px !important;
  color: rgba(0, 0, 0, 0.3) !important;
}
.ant-tabs-tab-active {
  color: rgba(0, 0, 0, 0.8) !important;
}
.ant-tabs-ink-bar-animated{
  width:  20px !important;
  margin-left: 5px !important;
  background: #5490F4 !important;
  border-radius: 10px !important;
}
</style>

<style>
/* 按钮阴影消失 */
.home-generation .v-btn--is-elevated{
  box-shadow: none;
}
.home-generation .v-btn--is-elevated{
  box-shadow: none;
}
.home-generation .v-btn--is-elevated{
  box-shadow: none;
}
.home-generation .v-btn--is-elevated{
  box-shadow: none;
}
</style>