<template>
    <div class="contact-content">
        <div class="feeedbackImage">
            <img src="../assets/contact/total-AI.png"/>
        </div>
        <div class="contact-title">
            关于我们
        </div>
        <img class="bgmcat" src="../assets/contact/bgmCat.png"/>
        <img class="deepmusic" src="../assets/contact/deepmusic.png"/>
        <div class="describe1">
            <span>BGM猫</span>是由<span>DeepMusic AI音乐团队</span>研发的用来<span>生成BGM</span>的工具，我们利用AI音乐生成技术使音乐的风格、情绪、时长、段落、能量等变得可控，旨在提供可商用和个人使用的优质音乐内容，降低音乐获取难度及成本。
        </div>
        <div class="describe1">
            目前产品还处于初期阶段，我们也在努力迭代产品，使得音乐生成更加智能精确，内容更加丰富多样。欢迎大家提出宝贵的意见和建议。
        </div>
        <div class="feedback-content">
            <div class="content-tabs">
                <span @click="changeTabIndex(0)" :class="tabIndex == 0 ? 'selected' : ''">反馈</span>
                <span @click="changeTabIndex(1)" :class="tabIndex == 1 ? 'selected' : ''">联系我们</span>
                <div class="tips-prompt" 
                    :class="tabIndex == 0 ? 'minWidth' : 'maxWidth'"
                    :style="tabIndex == 0 ? 'margin-left: 6px;' : 'margin-left: 70px;'">
                </div>
            </div>
            <div v-if="tabIndex == 0" class="content-container1">
                <v-select class="selected-control" :items="scoreArr" label="请评分" solo  v-model="scoreIndex"></v-select>
                <div class="feedback">
                    <span v-for="(item, index) in feedbackArr" :key="index" @click="chooseFeedBack(item)" :class="feedbackLabel == item ? 'choosedFeedBack' : ''">{{item}}</span>
                </div>
                <textarea placeholder="输入反馈内容" v-model="feedbackContent"></textarea>
                <br/>
                <div class="contact-submit">
                    <v-btn class="ma-2 submitButton" :class="submitState ? '' : 'cantsubmit'" :loading="submiting" :disabled="submiting" color="secondary" @click="sendFeedback">
                        提交
                    </v-btn>
                </div>
                
            </div>
            <div v-if="tabIndex == 1" class="content-container2">
                <div class="erCode">
                    <div class="weChat">
                        <div class="img-container">
                            <img src="../assets/customerService.png"/>
                        </div>
                        <div>微信</div>
                    </div>
                    <div class="tencent">
                        <div class="img-container">
                            <img src="../assets/contact/tencent.png"/>
                        </div>
                        <div>QQ</div>
                    </div>
                </div>
                <p class="contact"><img src="../assets/contact/tel.png"/>chenxy@lazycomposer.com</p>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import {axiosGet, axiosPost} from "../../src/axios.js"
import {ADD, LABELARR, FILEINFO, SOUNDTRACK} from "../../src/api.js"
export default {
    data: () => ({

        tabIndex: 0, 
        submiter: null,
        submiting: false,
        submitState: false, // 反馈提交按钮状态
        scoreIndex: "请评分", // 评分下标
        scoreArr: ['无感', '还行', '🐂 🍺', '很🐂 🍺'], // 评分数组
        feedbackContent: '', // 反馈内容
        feedbackLabel: '', // 反馈下标
        feedbackArr: ['功能建议', '曲风建议', 'BUG反馈', '其他'], // 反馈分类数组
    }),
    watch: {
      scoreIndex: function(val, oldVal) {
        if(val != -1) {
          this.submitState = true
        }
      },
      feedbackContent: function (val, oldVal) {
        if (this.feedbackLabel !== '' && val.length > 0) {
          this.submitState = true
        } else if (val.length === 0 && this.scoreIndex === "请评分") {
          this.submitState = false
        }
      }
    },
    mounted() {
        this.autoTextarea(document.getElementsByTagName("textarea")[0])
    },
    methods: {
        autoTextarea(elem, extra, maxHeight) {
            extra = extra || 0;
            var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
            isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
            addEvent = function (type, callback) {
                    elem.addEventListener ?
                            elem.addEventListener(type, callback, false) :
                            elem.attachEvent('on' + type, callback);
            },
            getStyle = elem.currentStyle ? function (name) {
            var val = elem.currentStyle[name];

            if (name === 'height' && val.search(/px/i) !== 1) {
                var rect = elem.getBoundingClientRect();
                return rect.bottom - rect.top -
                parseFloat(getStyle('paddingTop')) -
                parseFloat(getStyle('paddingBottom')) + 'px';        
            };

            return val;
            } : function (name) {
            return getComputedStyle(elem, null)[name];
            },
            minHeight = parseFloat(getStyle('height'));
            elem.style.resize = 'none';
            var change = function () {
            var scrollTop, height,
                padding = 0,
                style = elem.style;
    
            if (elem._length === elem.value.length) return;
            elem._length = elem.value.length;

            if (!isFirefox && !isOpera) {
                padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
            };
            scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            elem.style.height = minHeight + 'px';
            if (elem.scrollHeight > minHeight) {
                if (maxHeight && elem.scrollHeight > maxHeight) {
                height = maxHeight - padding;
                style.overflowY = 'auto';
                } else {
                height = elem.scrollHeight - padding;
                style.overflowY = 'hidden';
                };
                style.height = height + extra + 'px';
                scrollTop += parseInt(style.height) - elem.currHeight;
                document.body.scrollTop = scrollTop;
                document.documentElement.scrollTop = scrollTop;
                elem.currHeight = parseInt(style.height);
            };
            };
    
            addEvent('propertychange', change);
            addEvent('input', change);
            addEvent('focus', change);
            change();
        },
        changeTabIndex(index) {
            this.tabIndex = index;
        },
        // 提交反馈
        sendFeedback () {
            if (!this.submitState) {
                return
            }
            this.submiting = true
            this.submitState = false
            let index
            for(let i = 0; i < this.scoreArr.length; i++) {
                if(this.scoreArr[i] == this.scoreIndex) {
                    index = i;
                    break
                }
            }
            const data = {
                score: index,
                label: this.feedbackLabel,
                content: this.feedbackContent
            }
            axiosPost(ADD, qs.stringify(data)).then((res) => {
                this.submitState = true
                this.submiting = false
                this.$message.success("反馈成功");
            }).catch((error) => {
                this.submitState = true
                this.submiting = false
                this.$message.error("反馈失败,请再试一次");
            })
        },
        // 反馈模块
        chooseFeedBack (index) {
            this.feedbackLabel = index
            if (this.feedbackContent !== '') {
                this.submitState = true;
            }
        },
        showTab() {
            this.$emit("showTab")
        },
        backOffRoute() {
            this.$router.back();
        }
    }
}

</script>

<style lang="scss" scoped>
.contact-content{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 18px 20px 0px;
    .feeedbackImage{
        position: absolute;
        top: 0px;
        right: 0;
        z-index: 0;
        img{
            width: calc(262 / 375 * 100vw);
        }
    }
    .contact-title{
        font-family: 'PangMenZhengDao';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .bgmcat{
        margin-top: 37px;
        width: calc(97 / 375 * 100vw);
    }
    .deepmusic{
        display: block;
        margin-top: 8px;
        width: calc(165 / 375 * 100vw);
    }
    .describe1{
        margin-top: 14px;
        padding: 0 6px;
        font-family: 'PingFang SC';
        font-size: 15px;
        line-height: 146.5%;
        text-align: justify;
        letter-spacing: 0.065em;
        color: rgba(0, 0, 0, 0.8);
        span{
            font-weight: 500;
        }
    }
    .feedback-content{
        margin-top: 26px;
        .content-tabs{
            position: relative;
            width: 135px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.3);
                cursor: pointer;
            }
            .selected{
                color: rgba(0, 0, 0, 0.8);
                font-size: 17px;
            }
            .tips-prompt{
                position: absolute;
                height: 2px;
                top: 22px;
                background: #5490F4;
                border-radius: 10px;
            }
            .minWidth{
                width: 25px;
            }
            .maxWidth{
                width: 58px
            }
        }
        .content-container1{
            .selected-control{
                margin-top: 24px;
            }
            .feedback{
                span{
                    cursor: pointer;
                    margin-right: 15px;
                    display: inline-block;
                    padding:  5px 10px;
                    border-radius: 4px;
                    background: #F3F3F3;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: center;
                    color: #666666;
                    border: 1px solid #F3F3F3;
                }
                .choosedFeedBack{
                    background: #F6F6F6;
                    color: #5490F4;
                    font-weight: 500;
                    border: 1px solid #5490F4;
                }
            }
            textarea{
                margin-top: 40px;
                width: 89.34vw;
                max-height: 100px;
                outline: none;
                resize: none;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0 !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #333;
                display: block;
                height: 20px;
                overflow:hidden;
            }
            textarea::-webkit-input-placeholder {
                color: #B8B8B8;
            }
            textarea:-moz-placeholder {
                color: #B8B8B8;
            }
            textarea::-moz-placeholder {
                color: #B8B8B8;
            }
            textarea::-ms-input-placeholder {
                color: #B8B8B8;
            }
            .contact-submit{
                width: 100vw;
                margin-left: -8px;
                .submitButton{
                    width: calc(100% - 40px);
                    height: 38px;
                    font-size: 16px;
                    background: #000 !important;
                    border-radius: 20px;
                    line-height: 22px;
                }
                .cantsubmit{
                    color: #999999;
                    background: #E5E5E5 !important;
                }
                .custom-loader {
                    animation: loader 1s infinite;
                    display: flex;
                }
                @-moz-keyframes loader {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                @-webkit-keyframes loader {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                @-o-keyframes loader {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                @keyframes loader {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
            
        }
        .content-container2{
            margin-top: 24px;
            .erCode{
                display: flex;
                >div{
                    width: 120px;
                    margin-right: 16px !important;
                    .img-container{
                        margin-top: -4px;
                        width: 120px;
                        height: 120px;
                        background: #FFFFFF;
                        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                        padding: 12px;
                        img{
                            width: 96px;
                            height: 96px;
                        }
                    }
                    >div{
                        margin-top: 4px;
                        text-align: center;
                        font-family: PingFang SC;
                        font-style: normal;
                        font-weight: 200;
                        font-size: 12px;
                        line-height: 17px;
                        color: #000000;
                    }
                }
                
            }
            .contact{
                margin-top: 20px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.005em;
                color: #666666;
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
            }
        }
    }
}
</style>


<style>
/* 反馈选项框 */
.selected-control .v-input__slot{
  border-radius: 0 !important;
  width: 88px !important;
  height: 18px !important;
  min-height: 30px !important;
  padding:  0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  background: #f9f9f9 !important;
  box-shadow: none !important;
}
.selected-control label{
  font-size: 14px !important;
  color: #666666 !important;
}
.selected-control .v-select__selections {
  font-size: 14px !important;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-menu__content .v-list{
  padding: 0 !important;
  text-align: center !important;
}

#list-35 .primary--text{
  color: #f3f3f3 !important;
}

.v-list .v-list-item--active{
  background:  #f3f3f3  !important;
}

.v-list-item--active .v-list-item__content{
  color: #000 !important;
}
.v-icon.theme--light{
    color: #C4C4C4 !important;
}
</style>


<style>
/* 按钮阴影消失 */
.contact-submit .v-btn--is-elevated{
  box-shadow: none;
}
.contact-submit .v-btn--is-elevated{
  box-shadow: none;
}
.contact-submit .v-btn--is-elevated{
  box-shadow: none;
}
.contact-submit .v-btn--is-elevated{
  box-shadow: none;
}
</style>


<style>
.v-snack__wrapper{
  min-width: 200px !important;
}
.v-snack__content{
  padding: 0 0 0 8px !important;
  text-align: center !important;
}
</style>