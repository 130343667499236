import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import { VBtn, VApp, VMain, VSelect } from './plugins/vuetify'

// import Antd from 'ant-design-vue'
import { Tabs, Drawer, Select, Icon, Row, Col, Slider, message   } from 'ant-design-vue'

import 'ant-design-vue/dist/antd.css'
import './common/font/font.css'
import './common/iconfont/iconfont.css'
import './common/iconfont2/iconfont.css'

import infiniteScroll from 'vue-infinite-scroll'


Vue.config.productionTip = false
// Vue.use(Antd);
Vue.use(Tabs)
Vue.use(Drawer)
Vue.use(Select)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Slider)

Vue.prototype.$message = message;
message.config({
    duration: 1,// 持续时间
    top:`90vh`, // 到页面顶部距离
    maxCount: 1 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});


Vue.use(infiniteScroll)

new Vue({
  router,
  store,
  vuetify,
  // VBtn, VApp, VMain, VSelect ,
  render: h => h(App)
}).$mount('#app')
