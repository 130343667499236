<template>
    <div class="player">
        <p class="audioTimePosition">
            <span id="playedTime" class="playedTime" :style="{left: (playedTime / audioDuration * wavesurferWidth) + 'px'}">
                {{playedTime | delayDuration}}
            </span>
        </p>
        <div class="right-bottom">
            <div class="bottom1">
                <span v-if="playOrNot">
                    <i class="iconfont icon-bofang"
                        style="font-size: 24px;" @click="playAudio"></i>
                </span>
                <span v-if="!playOrNot">
                    <i class="iconfont icon-icon_playbar_pause_24"
                        style="font-size: 24px;" @click="playAudio"></i>
                </span>
                <span v-if="!collectionTip" class="middle-span" @click="collectSongOrNot('y')">
                    <i class="iconfont icon-shoucang1" 
                        style="font-size: 16px; z-index:10 !important;">
                    </i>
                </span>
                <span v-if="collectionTip" class="middle-span" @click="collectSongOrNot('n')">
                    <i class="iconfont icon-icon_leftbar_collect_select_241" 
                        style="font-size: 16px;color: #D36666;z-index:10 !important;">
                    </i>
                </span>
            </div>
            <div class="bottom2">
                <p class="timeLine"><span :style="{left: (playedTime / audioDuration * wavesurferWidth) - 2 + 'px'}"></span></p>
                <div id="wavesurfer" style="z-index: 0 !important;" @click="getPosition($event)"></div>
            </div>
        </div>
        <!-- 爆点 图片格式 不可拖动 -->
        <p class="burstPoint-576" v-if="burstPointTime > -1">
            <span class="burstPoint-timeLine" 
                :style="{left: (burstPointTime / audioDuration * wavesurferWidth) - 1 + 'px'}">
            </span><br/>
            <span class="burstPoint-iconfont" 
                :style="{left: (burstPointTime / audioDuration * wavesurferWidth) - 6.5 + 'px'}">
                <i class="iconfont icon-shixinhuomiao"></i>
            </span><br/>
            <span class="burstPoint-time" 
                :style="{left: (burstPointTime / audioDuration * wavesurferWidth) - 14 + 'px'}">
                {{burstPointTime | delayDuration}}
            </span>
        </p>
    </div>
</template>

<script>
import qs from 'qs'
import {axiosGet, axiosPost} from "../../src/axios.js"
import WaveSurfer from 'wavesurfer.js'
import {POINT, FAVORITEORNOT, DOWNLOADFILE, downloadHost} from '../../src/api.js'
import { send_record } from  "../utils/umeng_util"
export default {
    props:{
        wavesurferAdr: String,
        burstPointTime: Number,
        musicId: Number,
        structureTimeArr: Array
    },
    data: () => ({
        burstPointtime: -1, // 父组件传递的爆点时间
        leftLocation: -1, // 与爆点相关的位置

        burstPointTip: false, // 拖动修改爆点位置 是否显示 
        burstPointTimeTip: true, // 播放器最下方提示
        wavesurfer: null, // 波形图对象
        audioDuration: 0, // 音频时长
        playedTime: 0, // 已播放时长
        wavesurferWidth: 0, // 波形图宽度
        playOrNot: false, // 是否播放音乐
        playOrNot2: false, // 波形图未渲染完成不可以点击播放
        collectionTip: Boolean
    }),
    filters: {
        delayDuration (dur) {
            const duration = Math.round(dur)
            const min = Math.floor(duration / 60)
            const sec = (duration % 60) > 9 ? (duration % 60) : '0' + (duration % 60)
            return min + ':' + sec
        }
    },
    directives: {
        drag(el, binding, vnode) {
            let dragBox = el;
            // vnode.context 是整个vue实例 相当于this
            let that = vnode.context;
            dragBox.onmousedown = e => {
                that.burstPointTimeTip = true;
                that.burstPointTip = false;
                //算出鼠标相对元素的位置
                let disX = e.clientX - dragBox.offsetLeft;
                document.onmousemove = e => {
                    that.burstPointTip = false;
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    that.leftLocation =  e.clientX - disX > that.wavesurferWidth ? that.wavesurferWidth : e.clientX - disX < 0 ? 0 : e.clientX - disX
                    //移动当前元素
                    // dragBox.style.left = left + "px";
                };
                document.onmouseup = e => {
                    that.burstPointTimeTip = false;
                    //鼠标弹起来的时候不再移动
                    document.onmousemove = null;
                    //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
                    document.onmouseup = null;
                };
            };
        }
    },
    watch: {
        wavesurferAdr:  function(val, oldVal) {
            this.createWaveform();
            this.playedTime = 0;
        },
        leftLocation: function(val, oldVal) {
            this.burstPointtime = val * this.audioDuration / this.wavesurferWidth;
        },
    },
    beforeMount() {
        this.burstPointtime = this.$props.burstPointTime;
        this.setCollectionTip();
    },
    mounted() {
    },
    methods: {
        createMusic() {
            this.$emit("createAgain", this.burstPointtime);
            document.getElementById("animationn").className = "animationn"
        },
        changeBurstPointTipOver() {
            this.burstPointTip = true;
            let styleLeft = document.getElementsByClassName("burstPoint-img")[0].style.left;
            let left = Number(styleLeft.substring(0, styleLeft.length - 2))
            this.$nextTick(()=> {
                document.getElementsByClassName("burstPoint-tip")[0].style.left = left - 50 + "px";
            })
        },
        getRandomColor(){
            return  '#' + Math.random().toString(16).substr(2, 6).toUpperCase();
        },  
        setCollectionTip() {
            let href  = window.location.href;
            if(href.indexOf('/home') > -1){
                this.collectionTip = false;
            }else if(href.indexOf('/collection') > -1){
                this.collectionTip = true;
            }
        },
        destoryWave() {
            if(this.wavesurfer != null) {
               this.wavesurfer.destroy(); 
            }
        },
        // 画波形图
        createWaveform () {
            this.playOrNot = false
            this.playOrNot2 = false
            this.burstPointTimeTip = true
            this.$nextTick(() => {
                if (document.getElementsByTagName('wave').length === 0) {
                    this.wavesurfer = WaveSurfer.create({
                        container: '#wavesurfer',
                        waveColor: '#BCBCBC',
                        height: 24,
                        barWidth: 1,
                        progressColor: '#000000',
                        barGap: 2,
                        cursorColor: 'rgba(0, 0, 0, 0.3)'
                    })
                }
                this.wavesurfer.load(this.wavesurferAdr);
                this.wavesurfer.on('finish', () => {
                    this.playOrNot = false;
                    this.$emit("playAudioTip", this.playOrNot)
                })
                this.wavesurfer.on('ready', () => {
                    if(window.location.href.indexOf("/collection") > -1) {
                        this.wavesurfer.play();
                        this.playOrNot = true;
                        this.$emit("playAudioTip", this.playOrNot)
                    }
                    this.playOrNot2 = true
                    this.buttonState = true;
                    this.$emit("loadFinish");
                    this.audioDuration = this.wavesurfer.getDuration()
                    this.getWidth();
                    this.wavesurfer.setVolume(0.5)
                    if(localStorage.getItem("cancelFavoriteList") == null) {
                        this.collectionTip = true;
                        return
                    }
                    let arr = localStorage.getItem("cancelFavoriteList").split(",");
                    let index = arr.findIndex(items => items == this.$props.musicId);
                    if(index > -1) {
                        this.collectionTip = false;
                    } else {
                        this.collectionTip = true;
                    }
                })
                this.wavesurfer.on('audioprocess', () => {
                    this.playedTime = this.wavesurfer.getCurrentTime()
                })
            })
        },
        // 播放暂停音频
        playAudio () {
            if(!this.playOrNot2){
                return
            }
            this.wavesurfer.playPause()
            this.playOrNot = !this.playOrNot;
            this.$emit("playAudioTip", this.playOrNot)
        },
        collectSongOrNot(str) {
            if(str == 'y') {
                // 收藏这首歌曲
                if(localStorage.getItem("token") == null && window.location.href.indexOf('/home') > -1) {
                    // 在首页未登录状态
                    sessionStorage.setItem("wavesurferAdr", this.$props.wavesurferAdr);
                    sessionStorage.setItem("burstPointTime", this.$props.burstPointTime);
                    sessionStorage.setItem("musicId", this.$props.musicId);
                    this.$router.push({path: '/login?index=0'});
                    localStorage.setItem("loginFrom",'/home')
                }  else {
                    this.favoriteOrNot(1)
                }
            } else {
                // 取消收藏这首歌曲
                this.favoriteOrNot(0);
            }
        },
        favoriteOrNot(status) {
            if(status == 0) {
                // 取消收藏
                this.collectionTip = false;
            } else if(status == 1) {
                // 收藏
                this.collectionTip  = true;
            }
            let data = {
                musicId: this.$props.musicId,
                status: status
            }
            axiosPost(FAVORITEORNOT,  qs.stringify(data))
            .then(res => {
                let cancelFavoriteList = localStorage.getItem("cancelFavoriteList");
                if(status == 0) {
                    if(window.location.href.indexOf("collection") > -1) {
                        localStorage.setItem("cancelFavoriteList", cancelFavoriteList + ',' + this.$props.musicId);
                    }
                    // 取消收藏
                    this.collectionTip = false;
                    // console.log("取消收藏成功");
                    send_record("collction_cancel_forever");
                } else if(status == 1) {
                    // 收藏
                    if(window.location.href.indexOf("collection") > -1){
                        let arr = cancelFavoriteList.split(",");
                        let index = arr.findIndex(items => items == this.$props.musicId);
                        if(index > -1) {
                            arr.splice(index, 1);
                        }
                        localStorage.setItem("cancelFavoriteList", arr.join(','));
                    }
                    this.collectionTip  = true;
                    send_record("collction_forever");
                    // console.log("收藏成功");
                }
            }).catch(err => {
                if(status == 0) {
                    // 取消收藏
                    this.collectionTip = false;
                    // console.log("取消收藏失败");
                } else if(status == 1) {
                    // 收藏
                    this.collectionTip  = true;
                    // console.log("收藏失败");
                }
                console.log(err, err)
                this.$message.error(err.msg);
            })
        },
        changeCollectionTipFalse() {
            this.collectionTip = false;
        },
        changeCollectionTip(id) {
            if(id == this.$props.musicId) {
                this.collectionTip = false;
            }
        },
        changeCollectionTipTrue() {
            this.collectionTip = true;
        },
        // 点击波形图获取时间
        getPosition (e) {
            this.playedTime = e.offsetX / this.wavesurferWidth * this.audioDuration
        },
        // 获取宽度
      getWidth () {
        let wavesurfer = document.getElementById("wavesurfer");
        this.wavesurferWidth = wavesurfer.offsetWidth;
        this.leftLocation = this.$props.burstPointTime / this.audioDuration * this.wavesurferWidth;
      },
    }
}
</script>

<style lang="scss">
.player{
    user-select:none;
    width: 100vw;
    padding: 0 20px;
    .audioTimePosition{
        // 偏移量 80 - 16
        margin-left: 64px;
        height: 20px;
        margin-top: -20px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000;
        .playedTime{
            padding: 2px 6px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #FFFFFF;
            position: relative;
            background: rgba(41, 41, 41, 0.66);
            border-radius: 23px;
        }
    }
    .right-bottom{
        display: flex;
        .bottom1{
            margin-right: 20px;
            margin-top: -16px;
            height: 56px;
            display: inline-block;
            line-height: 56px;
            span{
                margin-right: 10px;
                i{
                    cursor: pointer;
                }
            }
            span:nth-child(2){
                position: relative;
                top: -3px;
            }
            
        }
        .bottom2{
            width: calc(100vw - 120px);
            .timeLine{
                height: 56px;
                margin-top: -16px;
                position: fixed;
                span{
                    position: relative;
                    display: inline-block;
                    height: 56px !important;
                    border: 1px solid rgba(0, 0, 0, 0.3); 
                }
                span:nth-child(1) {
                    margin-right: 9px;
                }
            }
            div{
                width: 96.5%;
                height:  56px;
            }
        }
    }
    .burstPoint-576{
        margin-left: 80px;
        margin-top: -16px;
        .burstPoint-timeLine{
            display: inline-block;
            position: relative;
            top: -56px;
            background: #D91B1B;
            width: 1px;
            height: 56px;
        }
        .burstPoint-iconfont{
            position: relative;
            top: -81px;
            i{
                color: #DE1C1C;
            }
        }
        .burstPoint-time {
            z-index: 100;
            position: relative;
            top: -115px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #C47070;
        }
    }
}
</style>