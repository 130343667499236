<template>
    <div class="user-content">
        <div class="user-title">
            个人中心
        </div>
        <div class="user-portrait">
            <div class="portrait-img">
                <img :src="userHeadUrl"/>
            </div>
            <div class="portrait-name">
                <div class="userNickname-model" v-if="!changeNickNameTip" 
                    :class="userVipSort == 0 ? 'maxTop' : 'minTop'">
                    <div class="p">{{nickname}}</div>
                    <i class="iconfont icon-icon_input_24"
                    @click="getFocus"></i>
                </div>
                <div class="userNickname-input" v-if="changeNickNameTip"
                    :class="userVipSort == 0 ? 'maxTop' : 'minTop'">
                    <input v-model="nickname" ref="input" @blur="changeUserName"/>
                    <i class="iconfont icon-icon_input_24"
                        @click="getFocus"></i>
                </div>
                <div class="userSort" v-if="userVipSort == 1">创作者</div>
                <div class="userSort" v-if="userVipSort == 2">专业创作者</div>
                <div class="userSort" v-if="userVipSort == 9">企业会员</div>
            </div>
        </div>
        <div class="user-aboutDownLoad">
            <div class="user-downloadNum">
                <div>今日已生成</div>
                <div>{{createdNum | delayNum}}<span>首</span></div>
            </div>
            <div class="user-downloadNumm">
                <div>累计已生成</div>
                <div>{{createdNumTotal | delayNum}}<span>首</span></div>
            </div>
            <div class="user-createdNum">
                <div>累计已下载</div>
                <div>{{downloadNumTotal | delayNum}}<span>首</span></div>
            </div>
        </div>
        <div class="user-aboutInformation">
            <div class="infor-title">
                账号管理
            </div>
            <div class="infor-content infor-telphone"  @click="changeTel">
                <div class="infor-content-left infor-tel-left">
                    <div>手机号码</div>
                </div>
                <div class="infor-content-right infor-tel-right">
                    {{telphone | delayTel}}
                    <i class="iconfont icon-jin"></i>
                </div>
            </div>
            <div class="infor-content infor-password" @click="changePwd">
                <div class="infor-content-left infor-pass-left">
                    <div>账户密码</div>
                </div>
                <div class="infor-content-right infor-pass-right">
                    <span v-if="needPassword">设置密码</span>
                    <span v-if="!needPassword">修改密码</span>
                    <i class="iconfont icon-jin"></i>
                </div>
            </div>
        </div>
        <div class="user-buyHistory">
            <div class="infor-title">
                购买记录
            </div>
            <div class="gotoPC">
                此功能请移步PC端体验
            </div>
        </div>
        <div @click="logout" class="user-logout">
            退出
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import {axiosGet, axiosPost} from "../../src/axios.js"
import {GETUSERINFORMATION, CHANGEINFORMATION} from "../../src/api.js"
import { send_record } from "../utils/umeng_util";
export default {
    data: () => ({
        userVipSort: -1,
        needPassword: -1,

        changeNickNameTip: false,

        userHeadUrl: '',
        nickname: "小灵",
        createdNum: 0,
        createdNumTotal: 0,
        downloadNumTotal: 0,
        telphone: '',
        emailAdr: 'liuwj@lazycomposer.com'
    }),
    filters: {
        delayNum(index) {
            if(index < 10 && index != 0) {
                return '0' + index
            }else if(index == 0) {
                return '0' + 0
            }else {
                return index
            }
        },
        delayTel(index) {
            return index.substring(0,3) + " **** " + index.substring(7,11)
        }
    },
    mounted() {
        if(localStorage.getItem("token") == null) {
            this.$router.push({path: '/'})
        } else {
            this.getUserInformation();
        }
        
    },
    methods: {
        uploadUserHeadUrl(e) {
            console.log(this.$refs.uploadUserHeadUrl.files[0])
        },
        changeUserHeadUrl() {},
        getUserInformation() {
            axiosGet(GETUSERINFORMATION)
                .then(res => {
                    this.needPassword = res.data.needPassword;
                    this.userHeadUrl = res.data.headerUrl;
                    this.createdNum = res.data.create_today;
                    this.createdNumTotal = res.data.create_music_count;
                    this.downloadNumTotal  = res.data.download_music_count;
                    this.telphone  = res.data.phone
                    this.nickname = res.data.nickname
                    this.userVipSort = res.data.vip
                }).catch(err => {
                    this.$message.error(err.data.msg);
                })
        },
        logout()  {
            this.$router.push({path: '/login?index=11'});
            localStorage.setItem("loginFrom",'/user')
        },
        changePwd() {
            if(!this.needPassword) {
                this.$router.push({path: '/login?index=2&setPwdFrom=userChangePwd'});
            }else {
                this.$router.push({path: '/login?index=2&setPwdFrom=userSetPwd'});
            }
            localStorage.setItem("loginFrom",'/user')
        },
        changeTel() {
            this.$router.push({path: '/login?index=2&setPwdFrom=userChangeTel'});
            localStorage.setItem("loginFrom",'/user')
        },
        getFocus() {
            this.changeNickNameTip = true;
            this.$nextTick(()=> {
                this.$refs.input.focus();
                this.$refs.input.select();
            })
        },
        showTab() {
            this.$emit("showTab")
        },
        changeUserName() {
            this.changeNickNameTip  = false;
            let data = {
                phone: this.telphone,
                nickname: this.nickname
            }
            axiosPost(CHANGEINFORMATION, qs.stringify(data))
                .then((res) => {
                    this.getUserInformation();
                    send_record("user_change_nickname");
                }).catch( err => {
                     if(err.data.code == -1) {
                        this.$message.error(err.data.msg);
                    } else {
                        this.$message.error("昵称修改失败,请重新尝试!");
                    }
                    this.getUserInformation()
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.user-content{
    width: 100vw;
    min-height: calc(100vh - 44px);
    padding: 0 20px;
    background: #F6F6F6;
    .user-title{
        padding-top: 18px;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.025em;
        color: #000000;
        font-family: 'PangMenZhengDao';
    }
    .user-portrait{
        margin-top: 26px;
        height: 60px;
        display: flex;
        .portrait-img{
            width: 60px;
            height: 60px;
            margin-right: 16px;
            border-radius: 100%;
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.1));
            img{
                width: 56px;
                height: 56px;
                border-radius: 100%;
            }
        }
        .portrait-name{
            >div{
                display: flex;
            }
            .maxTop{
                margin-top: 19px;
            }
            .minTop{
                margin-top: 4.5px;
            }
            .userNickname-model{
                line-height: 22px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                color: #000000;
                opacity: 0.8;
                .p{
                    white-space:nowrap;
                    text-overflow:ellipsis; 
                    overflow:hidden;
                    max-width: 170px;
                    display: inline-block;
                    margin-right: 4px;
                }
                i{
                    color: #B8B8B8;
                }
            }
            .userNickname-input{
                line-height: 22px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                color: #000000;
                opacity: 0.8;
                input{
                    border:  none;
                    outline: none;
                }
                i{
                    color: #B8B8B8;
                }
            }
            .userSort{
                display: inline-block;
                padding: 2px 4px;
                margin-top: 8px;
                background: #4C68FB;
                box-shadow: 2px 4px 8px rgba(76, 104, 251, 0.2);
                border-radius: 8px 0px;

                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.025em;
                color: #FFFFFF;
                opacity: 0.8;
            }
        }
    }
    .user-aboutDownLoad{
        width: calc(100vw - 40px);
        margin-top: 26px;
        display: flex;
        height: 102px;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px 12px;
        >div{
            div:nth-child(2) {
                margin-top: 22px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
                letter-spacing: 0.025em;
                color: #5490F4;
                span{
                    margin-left: 8px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 131%;
                    letter-spacing: 0.025em;
                    color: #B8B8B8;
                }
            }
            div:nth-child(1) {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 134.5%;
                letter-spacing: 0.025em;
                color: #999999;
            }
        }
        >div:nth-child(1) {
            text-align: center;
            width: calc((100vw - 64px) / 3 - 16px);
        }
        >div:nth-child(2) {
            width: calc((100vw - 64px) / 3);
            text-align: center;
            border-left: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
        }
        >div:nth-child(3) {
            text-align: center;
            width: calc((100vw - 64px) / 3 - 16px);
        }
    }
    .user-aboutInformation{
        margin-top: 26px;
        .infor-title{
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 134.5%;
            display: flex;
            align-items: center;
            letter-spacing: 0.025em;
            color: #000000;
            margin-bottom: 10px;
        }
        .infor-content{
            border-bottom: 1px solid #EDEDED;
            margin-bottom: 6px;
            display: flex;
            justify-content: space-between;
            height: 36px;
            color: #333333;
            .infor-content-left{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 36px;
                display: flex;
                align-items: center;
                letter-spacing: 0.025em;

                color: #333333;
            }
            .infor-content-right{
                line-height: 36px;
                font-size: 12px;
                margin-right:  4px;
                color: #BDBDBD;
            }
            .infor-tel-right{
                color: #999999;
                i{
                    position: relative;
                    top: 2px;
                    margin-left: 4px;
                    color: #BDBDBD;
                }
            }
            .infor-pass-right{
                color: #999999;
                i{
                    position: relative;
                    top: 2px;
                    margin-left: 4px;
                    color: #BDBDBD;
                }
            }
        }
    }
    .user-buyHistory{
        margin-top: 26px;
        .infor-title{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 134.5%;
            letter-spacing: 0.025em;
            color: #000000;
        }
        .gotoPC{
            margin-top: 16px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 128.5%;
            letter-spacing: 0.025em;
            color: #333333;
        }
    }
    .user-logout{
        margin-top: 48px;
        height: 38px;
        background: #FFFFFF;
        text-align: center;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.04);
        border-radius: 24px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 38px;
        text-align: center;

        color: #D36666;
    }
}
</style>
