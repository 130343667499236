<template>
    <div class="collection-content">
        <div class="collection-title">
            收藏
        </div>
        <div class="collection-nothing" v-if="pageShowContent == 1">
            <div class="nothing-decribe">你还没有收藏歌曲哦...</div>
            <span class="goToCollection" @click="goToCollection">去收藏</span>
        </div>
        <div class="collection-nothing" v-if="pageShowContent == 3">
            <div class="nothing-decribe">正在加载中...</div>
        </div>
        <div class="collection-container" 
            style="border: 1px solid rgba(0,0,0,0);" 
            v-infinite-scroll="getAudioArr" infinite-scroll-disabled="busy" 
            infinite-scroll-distance="10">
            <div class="table-tbody-tr"
                :style="playingId == item.musicId  ? 'background: #fff;' : ''"
                v-for="(item, index) in audioArr" :key="index">

                <div class="table-tbody-index"><span>{{index+1 | delayIndex}}</span></div>
                <span class="table-tbody-name">
                    <p :style="playingId == item.musicId  ? 'color: #5490F4;' : ''">{{item.musicName}}</p>
                    <p>{{item.create_time | delayCreateTime}}</p>
                </span>
                <span v-if="item.use_type == 'label'" class="table-tbody-label">
                    <div class="containerr" v-for="(itemm, indexx) in item.keywords"
                            :key="indexx">
                        <span class="choosedlabel-content" :class="item.keywords.length == 1 ? 'justOne' : item.keywords.length == 2 ? 'justSec' : 'justThi'">
                            {{itemm}}
                        </span>
                    </div>
                </span>
                <span class="table-tbody-text" v-if="item.use_type == 'text'">
                    {{item.keywords}}
                </span>
                <div class="table-tbody-playAudio">
                    <span class="table-tbody-tip"  v-if="playingId != item.musicId">
                        <i @click="shishiClick(index)" 
                            class="iconfont icon-icon_playbar_pause_24"
                            >
                        </i>
                    </span>
                    <span class="table-tbody-tip" v-if="!playOrNot && playingId == item.musicId">
                        <i @click="shishiClick(index)" 
                            class="iconfont icon-icon_playbar_pause_24">
                        </i>
                    </span>
                    <span class="table-tbody-tip" v-if="playOrNot && playingId == item.musicId">
                        <i @click="shishiClick(index)" 
                            class="iconfont icon-bofang" 
                            :style="playingId == item.musicId  ? 'color: #5490F4;' : ''"
                            >
                        </i>
                    </span>
                </div>
              </div>
        </div>
        <div class="loading-container" v-show="loadingTip">
            <span>
                <i class="iconfont icon-jiazai"></i>
            </span>
            加载中
        </div>
         <div class="collection-player" v-show="audioShow">
            <player 
                ref="player"
                :wavesurferAdr="wavesurferAdr" 
                :burstPointTime="burstPointTime"
                :musicId="musicId"
                @spliceAudioArr="spliceAudioArr"
                @playAudioTip="playAudioTip"
                >
            </player>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
import Player from "./player"
import {axiosGet, axiosPost} from "../../src/axios.js"
import {LABELARR, FAVORITEORNOT, FAVORITELIST} from "../../src/api.js"
export default {
    components: {
        Player
    },
    data:() => ({
        audioArr:[], // 显示的音频数组

        labelArr: [], // 标签数组
        loadingTip: true,


        pageShowContent: -1, // 是否登录 0未登录 1登录未收藏 2正常显示

        playOrNot: false, // 是否播放

        pageCurrent: 1, // 当前页
        audioShow: false, // 播放器是否显示

        wavesurferAdr: '', // 音频地址
        // wavesurferAdr: 'http://192.168.2.62:8008/static/1620791130509833000/audio1-12s.mp3', // 音频地址
        burstPointTime: -1,  // 爆点
        musicId: -1, // 音频ID
        structureTimeArr: [], // 结构时间点数组

        collectionTip: true, // 是否收藏
        playingId: -1, // 正在播放的音频
    }),
    filters: {
        delayCreateTime(createTime) {
            let date = new Date(Number(createTime * 1000))
            return date.getFullYear() + "/" + 
                ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1)  : '0' + (date.getMonth() + 1) )  + "/" + 
                (date.getDate() > 9 ? date.getDate() : '0' + date.getDate() )
        },
        delayIndex(index) {
            if(index < 10) {
                return '0' + index
            }else{
                return index
            }
        },
        delayDuration (dur) {
            const duration = Math.round(dur)
            const min = Math.floor(duration / 60)
            const sec = (duration % 60) > 9 ? (duration % 60) : '0' + (duration % 60)
            return min + ':' + sec
        }
    },
    mounted() {
        this.judgePageShow()
    },
    methods: {
        judgePageShow() {
            // 是否登录 0未登录 1登录未收藏 2正常显示 3正在加载中
            if(localStorage.getItem("token") == null) {
                this.$router.push({path: '/'})
            }  else {
                this.pageShowContent = 3;
            }
        },
        playAudioTip(tip) {
            this.playOrNot = tip;
        },
        goToCollection() {
            this.$router.push({path: '/'});
        },
        playAudio() {
            this.$refs.player.playAudio();
        },
        shishiClick(index) {
            this.$refs.player.changeCollectionTipTrue()
            this.$refs.player.playAudio();
            this.playingId = this.audioArr[index].musicId;
            if(!this.audioShow){
                this.audioShow = true;
            }
            this.wavesurferAdr = this.audioArr[index].musicDemoFilePath;
        
            this.musicId = this.audioArr[index].musicId
            this.burstPointTime = this.audioArr[index].musicCalcParam.BurstTime
        },
        spliceAudioArr() {
            for(let i = 0; i < this.audioArr.length; i++) {
                if(this.playingId == this.audioArr[i].musicId) {
                    this.audioArr.splice(i, 1);
                    this.audioArrList.splice(i, 1);
                }
            }
        },
        getAudioArr() {
            if(localStorage.getItem("token") == null) {
                this.loadingTip = false;
                return
            }
            if(this.pageCurrent != 1 || this.pageCurrent != 2) {
                this.loadingTip = true;
            } else {
                this.loadingTip = false;
            }
            let data = {
                "page": this.pageCurrent,
                "size": 6,
            }
            axiosPost(FAVORITELIST,qs.stringify(data, { indices: false }), {
                headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'token': localStorage.getItem("token")
                }
            }).then(res => {
               
                this.loadingTip = false;
                this.pageCurrent = this.pageCurrent + 1;
                this.audioArr = this.audioArr.concat(res.data.favorite_list);
                if(this.audioArr.length ==  0) {
                    this.pageShowContent = 1;
                } else {
                    this.pageShowContent = 2;
                }
            }).catch(err => {
                let text
                if(err.data.code == 0) {
                    text = err.data.msg;
                } else {
                    text = "获取收藏列表失败,请再试一次";
                }
                this.$message.error(text);
            })
        },
        destoryWave() {
            this.$refs.player.destoryWave();
        },
    }
}
</script>
<style lang="scss" scoped>
.collection-content{
    width: 100vw;
    min-height: calc(100vh - 44px);
    padding-top: 16px;
    padding-left: 20px !important;
    padding: 16px 20px 0px;
    .collection-title{
        font-family: 'PangMenZhengDao';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .collection-nothing{
        position: relative;
        top: 90px;
        width: 100vw;
        margin-left: -5.33vw;
        text-align: center;
        .nothing-decribe{
            font-family: Songti SC;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 38px;
            letter-spacing: 1.3px;
            color: #B8B8B8;
        }
        .goToCollection{
            display: inline-block;
            margin-top: 80px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 8px 46px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #000000;
        }
    }
    .collection-container{
        margin-top: 20px;
        height: 60vh;
        max-height: calc(61px * 6);
        overflow: auto;
        .table-tbody-tr{
            display: flex;
            height: 61px;
            overflow: hidden;
            .table-tbody-index{
                display: flex;
                align-items: center;
                width: 9.6%;
                span{
                    display: inline-block;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px !important;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #999999;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #E0E0E0;
                    border-radius: 3px;  
                }
                
            }
            .table-tbody-name{
                width: 33.3%;
                p:nth-child(1){
                    margin-top: 10px;
                    width: 90%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.025em;
                    color: #333333;
                }
                p:nth-child(2) {
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    color: #999999;
                    margin-top:  -12px;
                }
            }
            .table-tbody-text{
                text-align: center;
                width: 46.3vw;
                height: 60px;
                color: #666666;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 60px;
            }
            .table-tbody-label{
                text-align: center;
                margin-top: 18px;
                width: 46.3vw;
                height: 25px;
                display: flex;
                justify-content: space-around;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                .containerr{
                    text-align: center;
                }
                .justFir{
                    max-width: 44.63vw;
                }
                .justSec{
                    max-width: calc((46.3vw - 8px) / 2);
                }
                .justThi{
                    max-width: calc((46.3vw - 16px) / 3);
                }
                .choosedlabel-content{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: inline-block;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 25px;
                    letter-spacing: 0.02em;
                    color: #666666;
                    height: 25px;
                    padding: 0px 10px;
                    border: 1px solid #E0E0E0;
                    border-radius: 19px;
                    margin-right: 8px;
                }
                .choosedlabel-content:last-child{
                    margin-right: 0px;
                }
            }
            .table-tbody-playAudio{
                display: flex;
                align-items: center;
                i{
                    text-align: center;
                    font-size: 24px;
                    margin-left: 20%;
                }
            }
        }
    }
    .loading-container{
        margin-top: 32px;
        text-align: center;
        width: 100vw;
        margin-left: -5.33vw;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: #B8B8B8;
        span{
            position: relative;
            top: 1px;
        }
        span{
            display: inline-block;
            animation: ani-demo-spin 1.5s linear infinite;
        }
        @keyframes ani-demo-spin {
            from { transform: rotate(0deg);transform-origin:50% 50%;}
            50%  { transform: rotate(180deg);transform-origin:50% 50%;}
            to   { transform: rotate(360deg);transform-origin:50% 50%;}
        }
    }
    .collection-player{
        position: fixed;
        height: 56px;
        bottom: 17px;
        margin-left: -20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    }
}
</style>
