<template>
  <v-app>
    <v-main>
      <div id="tab-content">
        <tab-content @closeTabContainer="closeTabContainer" ref="TabContent" @destoryWave="destoryWave"></tab-content>
      </div>
      <div class="total-header" v-if="router == 'other'">
        <div @click="jumpHome">
          <img src="./assets/logo.png"/>
        </div>
        <div>
          <img src="./assets/gotoPC.png"/>
          <img src="./assets/tab.png" @click="showTab"/>
        </div>
        
      </div>
      <router-view
      ref="child"
      @showTab="showTab"/>
    </v-main>
  </v-app>
</template>

<script>
import TabContent from "./components/tabContent";
export default {
  name: 'App',
  components: {
    TabContent
  },
  data: () => ({
    router: "other"
  }),
  watch:{
    $route(to, from){
      if(to.path == "/login") {
        this.router = "login";
      }else {
        this.router = "other";
      }
    },
  },
  mounted() {
    if(window.location.href.indexOf("login") > -1) {
      this.router = "login";
    }else {
      this.router = "other";
    }
    localStorage.removeItem("templateId")
    localStorage.removeItem("cancelFavoriteList")
  },
  methods: {
    getBrowserInterfaceSize() {
      var pageHeight = window.innerHeight;

      if (typeof pageWidth != "number") {
          //在标准模式下面
          if (document.compatMode == "CSS1Compat" ) {
              pageHeight = document.documentElement.clientHeight;
          } else {
              pageHeight = window.body.clientHeight;
          }
      }
      this.$nextTick(()=> {
          console.log(document.getElementsByClassName("home-content")[0])
          document.getElementsByClassName("home-content")[0].style.minHeight = pageHeight + 'px';
          console.log(pageHeight)
      })
      
    },
    jumpHome() {
      this.$router.push({path: "/home"});
    },
    destoryWave() {
      this.$refs.child.destoryWave()
    },
    showTab() {
      document.getElementById("tab-content").style.transition = "left 0.5s";
      document.getElementById("tab-content").style.left = "0vw";
      this.$refs.TabContent.comfireTabIndex()
    },
    closeTabContainer() {
      document.getElementById("tab-content").style.transition = "left 0.5s";
      document.getElementById("tab-content").style.left = "100vw";
    }
  }
};
</script>
<style lang="scss">
@import './common/font/font.css';
.v-application--wrap{
  background: #f9f9f9;
  /* height: 100vh; */
}
.v-main{
  /* height: 100vh; */
  overflow-x: hidden;
}
</style>
<style lang="scss" scoped>
#tab-content{
  position: absolute;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  z-index: 1000 !important;
}
.total-header{
  height: 44px;
  background: #F9F9F9;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  div:nth-child(1) {
    img{
      margin-top: 10px;
      width: 24px;
    }
  }
  div:nth-child(2) {
    img:nth-child(1) {
      margin-top: 2.3px;
      margin-right: 12px;
      width: 133px;
    }
    img:nth-child(2) {
      margin-top: 10px;
      width: 24px;
    }
  }
}
</style>
