import Vue from 'vue'
import VueRouter from 'vue-router'
// import Hello from '../components/HelloWorld.vue'

import Home from '../components/home.vue'
import Collection from '../components/collection.vue'
import Contact from '../components/contact.vue'
import Login from '../components/login.vue'
import User from '../components/user.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user',
    name: 'User',
    component: User
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
